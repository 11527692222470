import {Injectable} from "@angular/core";
import {isNil, isNilOrBlank} from "../components/component-helpers/pilera-component-helpers";
import {SimpleCommunity} from "../model/community/community.model";
import {PortalType} from "../model/enum/portal-type.enum";
import {ClientCommunity} from "../model/client/client-commuties.model";
import {ClientCommunityEntity} from "../model/client/client-community-entity";

declare var pilera: any;
declare var Pilera: any;

declare var PileraPortal: any;


/**
 * PileraPortalBridgeService
 *
 * This service provides a hook into certain data and configuration that is hosted by the pilera portal app
 * In order to use this service, your module MUST be inserted into the rendered PHP Portal app
 *
 * NOTE: this service will not work if your module is in a child window, such as the new "vendor" app
 *
 *
 */
@Injectable()
export class PileraPortalBridgeService
{
	/**
	 * The current/active simple community
	 * This object is lazy loaded, and thus is a property of this service, opposed to a pass thru to the DOM
	 *
	 * @type {SimpleCommunity}
	 * @private
	 */
	private activeSimpleCommunity: SimpleCommunity;

	constructor()
	{
	}

	/**
	 * Returns true if we're in the pilera portal context
	 *
	 * @returns {boolean}
	 */
	public isInPortal(): boolean
	{
		// this was a real stinker to evaluate for some reason
		return (!isNil(window["PileraPortal"]));
	}


	/**
	 * returns the url for the php application, such as https://stage.pilera.com
	 *
	 * @returns {any}
	 */
	public getPhpRootUrl(): string
	{
		return Pilera.phpRootUrl;
	}

	/**
	 * Returns the pcs root REST url for the current environment.
	 *
	 * Like: https://stage-mike.pilera.com/pcs/rest
	 */
	public getPcsRestRootUrl(): string
	{
		return Pilera.pcsRestRootUrl;
	}

	/**
	 * returns the url for the node application, such as https://node-stage.pilera.com
	 *
	 * @returns {any}
	 */
	public getNodeRootUrl(): string
	{
		return Pilera.nodeRootUrl;
	}


	/**
	 * Returns true if the portal is in manager mode
	 *
	 * @returns {boolean}
	 */
	public isAreaManager(): boolean
	{
		return (pilera.area === "Manager");
	}

	/**
	 * Returns true if the portal is in resident mode
	 *
	 * @returns {boolean}
	 */
	public isAreaResident(): boolean
	{
		return (pilera.area === "Resident");
	}

	/**
	 * Returns true if the current user is a resident.
	 * NOTE: this method ACTUALLY uses the user type to determine this, not just the lazy/sloppy are we in the resident portal or not. (so better)
	 *
	 * @returns {boolean}
	 */
	public isUserAResident(): boolean
	{
		return (pilera.target.person.isResident);
	}

	/**
	 * Return the portals active client uuid
	 *
	 * @returns {any|string}
	 */
	public getActiveClientUUId(): string {
		return pilera.client.uuid;
	}

	/**
	 * Return the portals active client code
	 *
	 * @returns {any|string}
	 */
	public getActiveClientCode(): string
	{
		return pilera.client.code;
	}

	/**
	 * Returns a Client Community Entity populated with the active client and community uuids.
	 *
	 * @returns {ClientCommunityEntity}
	 */
	public getActiveClientCommunity(): ClientCommunityEntity
	{
		return new ClientCommunityEntity(this.getActiveClientUUId(), this.getActiveCommunityUUId());
	}

	/**
	 * Returns the user mapping UUID for the targetUUId occupant
	 *
	 * @returns string
	 */
	public getTargetOccupantMappingUUId(): string
	{
		return pilera.user.activeUserMappingUUId;
	}

	/**
	 * Returns the active usermapping uuid
	 *
	 * @returns {string}
	 */
	public getActiveUserMappingUUId(): string
	{
		return pilera.user.activeUserMappingUUId;
	}

	/**
	 * Returns the user uuid of the active user
	 * @returns {string}
	 */
	public getActiveUserUUId(): string
	{
		return pilera.user.uuid;
	}

	/**
	 * Always returns the current, logged in (or pretended as) user mapping uuid
	 *
	 * @returns {string}
	 */
	public getCurrentUserMappingUUId(): string
	{
		return pilera.user.currentUserMappingUUId;
	}

	/**
	 * Returns the unit that is targeted in the portal's uuid
	 *
	 * @returns {any}
	 */
	public getTargetUnitUUId(): string
	{

		// TODO: really, really bad (mike)
		// when in resident, the info is available in a different way than in manager
		// i thought this was all worked out, but seems no.. at least ngx is abstracted from the mayhem further

		if (pilera.target.unit.unitUUId !== "") return pilera.target.unit.unitUUId;

		return PileraPortal.Utilities.State.getActiveUnitUUId();
	}

	/**
	 * Returns the target unit in context.
	 * NOTE: you have to be in a view that has a unit context- Resident portal should always have one.
	 * BUT, in manager portal, you need to be in an occupant detail scope.
	 *
	 */
	public getTargetUnit(): Object
	{
		return PileraPortal.Utilities.State.getTargetUnit();
	}

	/**
	 * Returns the uuid of the unit that is currently active in portal
	 *
	 */
	public getActiveUnitUUId(): string
	{
		return pilera.user.activeUnitUUId;
	}

	/**
	 * Returns the account of the unit that is currently active in portal
	 *
	 */
	public getActiveUnitAccount(): string
	{
		return pilera.target.unit.accountNumber;
	}

	/**
	 * Returns the active units description.
	 *
	 * @returns {string}
	 */
	public getActiveUnitDescripion(): string
	{
		return pilera.target.unit.description;
	}

	/**
	 * Returns the active units name.
	 *
	 * @returns {string}
	 */
	public getActiveUnitName(): string
	{
		return pilera.target.unit.unitName;
	}

	/**
	 * Returns the active units building name.
	 *
	 * @returns {string}
	 */
	public getActiveUnitBuildingName(): string
	{
		return pilera.target.unit.buildingName;
	}

	/**
	 * Returns the active units street name.
	 *
	 * @returns {string}
	 */
	public getActiveUnitStreetName(): string
	{
		return pilera.target.unit.streetName;
	}

	/**
	 * Returns the portals active community uuid
	 *
	 * @returns {any}
	 */
	public getActiveCommunityUUId(): string
	{
		return pilera.community.uuid;
	}

	/**
	 * Returns the portals active community name
	 *
	 * @returns {any}
	 */
	public getActiveCommunityName(): string
	{
		return pilera.community.name;
	}

	/**
	 * Returns the active community timezone
	 * @returns {string}
	 */
	public getActiveCommunityTimeZone(): string
	{
		return pilera.community.timezone;
	}

	/**
	 * Returns the active communities start date
	 *
	 * @returns {string}
	 */
	public getActiveCommunityStartDate(): string
	{
		return pilera.community.startDate;
	}

	/**
	 * Returns the active community as a simple community object.
	 *
	 * @returns {SimpleCommunity}
	 */
	public getActiveSimpleCommunity(): SimpleCommunity
	{
		if (!isNilOrBlank(this.activeSimpleCommunity) && this.activeSimpleCommunity.uuid === this.getActiveCommunityUUId()) return this.activeSimpleCommunity;

		this.activeSimpleCommunity = new SimpleCommunity(this.getActiveCommunityUUId(), this.getActiveCommunityName())
		this.activeSimpleCommunity.active = true;
		this.activeSimpleCommunity.timeZone = this.getActiveCommunityTimeZone();

		return this.activeSimpleCommunity;
	}

	/**
	 * Returns the active communities provider type, such as "jenark"
	 *
	 * @returns {string}
	 */
	public getActiveCommunityProviderType(): string
	{
		return pilera.community.provider.name;
	}

	/**
	 * Returns true if the bridge (to portal) is available.
	 * Should be false in standalone ngx apps like vendor, etc
	 *
	 * @returns {boolean}
	 */
	public isBridgeAvailable(): boolean
	{
		return (!isNilOrBlank(window['pilera']));
	}

	/**
	 * Returns the active communities provider type, such as "jenark"
	 *
	 * @returns {string}
	 */
	public isActiveCommunityTypeOneThatHasBoardOfDirectors(): string
	{
		return pilera.community.isCommunityTypeOneThatHasBoardOfDirectors;
	}

	/**
	 * Returns true if the active community is apartments.
	 *
	 * @returns {boolean}
	 */
	public isActiveCommunityApartments(): boolean
	{
		return PileraPortal.Utilities.State.isApartments();
	}

	/**
	 * Returns the current users common name
	 *
	 * @returns {any}
	 */
	public getUserCommonName(): string
	{
		return pilera.user.commonname;
	}

	/**
	 * Returns the current users full name
	 *
	 * @returns {any}
	 */
	public getUserFullName(): string
	{
		return pilera.user.fullname;
	}

	/**
	 * Returns the current Target users full name
	 *
	 * @returns {string}
	 */
	public getTargetPersonFullName(): string
	{
		return pilera.target.person.fullName;
	}

	/**
	 * Returns the current Target user/persons uuid
	 *
	 * @returns {string}
	 */
	public getTargetPersonUUId(): string
	{
		return pilera.target.person.userUUId;
	}

	/**
	 * Returns the current users email address
	 *
	 * @returns (string)
	 */
	public getUserEmail(): string
	{
		return pilera.user.email;
	}

	/**
	 * Returns the current users primary phone
	 *
	 * @returns (string)
	 */
	public getUserPrimaryPhone(): string
	{
		return pilera.user.primaryphone;
	}

	/**
	 * Returns whether a a function is Read or Write or neither
	 *
	 * @param argFunctionName the function name like "ruleViolations"
	 * @returns {boolean}
	 */
	public isFunctionReadOrWrite(argFunctionName): boolean
	{
		return (this.isFunctionReadWrite(argFunctionName) || this.isFunctionReadOnly(argFunctionName));
	}

	/**
	 * Returns whether a a function is Read/Write or not
	 *
	 * @param argFunctionName the function name like "ruleViolations"
	 * @returns {boolean}
	 */
	public isFunctionReadWrite(argFunctionName): boolean
	{
		// TODO: move value to constants

		return (pilera.settings.functions[argFunctionName] === 1);
	}

	/**
	 * Returns whether a a function is Read only or not
	 *
	 * @param argFunctionName the function name like "ruleViolations"
	 * @returns {boolean}
	 */
	public isFunctionReadOnly(argFunctionName): boolean
	{
		// TODO: move value to constants
		return (pilera.settings.functions[argFunctionName] === 2);
	}

	/**
	 * Returns whether a a function is set to not show
	 *
	 * @param argFunctionName the function name like "ruleViolations"
	 * @returns {boolean}
	 */
	public isFunctionNoShow(argFunctionName): boolean
	{
		// TODO: move value to constants
		return (pilera.settings.functions[argFunctionName] === 0);
	}


	public showDialog(title: string, message: string, type: string = "error")
	{
		PileraPortal.trigger("ui:dialog:open", title, message, type);

	}


	/**
	 * Returns true of the angular app/component is embedded in easysite
	 *
	 */
	public isEasysiteEmbedded(): boolean
	{
		if (isNil(pilera.isEasysiteEmbedded) || pilera.isEasysiteEmbedded === false) return false;

		return pilera.isEasysiteEmbedded;
	}

	/**
	 * Returns true if the users context is an ez site admin and we're in an easysite context (ie, we're on an ez site, not the portal)
	 */
	public isEasySiteAdminInEasySiteContext(): boolean
	{
		// TODO: consider looking at permissions.. although I don't think they are complete on EZ site side

		if (!this.isEasysiteEmbedded()) return false;

		return (!isNil(pilera.auth.isSiteAdmin) && pilera.auth.isSiteAdmin === true);
	}

	/**
	 * Returns if the portals active community is CommHub
	 *
	 * @returns {boolean}
	 */
	public isActiveCommunityCommHub(): boolean
	{
		return pilera.community.isTypeCommHub;
	}

	/**
	 * Returns if the portals active community is RFP+ Only
	 *
	 * @returns {boolean}
	 */
	public isActiveCommunityRFPOnly(): boolean
	{
		return pilera.community.isRFPOnly;
	}

	/**
	 * Returns the portal type for the application, such as PILERA or COMMHUB
	 *
	 * @returns {PortalType}
	 */
	public getPortalType(): PortalType
	{
		if (this.isActiveCommunityCommHub())
		{
			return PortalType.COMMHUB;
		}

		if (this.isActiveCommunityRFPOnly())
		{
			return PortalType.RFPONLY;
		}

		return PortalType.PILERA;
	}

	/**
	 * Publish an event that the pilera app is listening for
	 *
	 * FOR EZ ONLY - use backbone pilera portal app events whenever possible
	 *
	 * @param event
	 * @param args
	 */
	public publishEvent(event: string, params?: any): void
	{
		pilera.bridge.publishEvent(event, params);
	}

	/**
	 * Subscribe to an event that the pilera app may publish
	 *
	 * FOR EZ ONLY - use backbone pilera portal app events whenever possible
	 *
	 * @param event
	 * @param params
	 */
	public subscribeEvent(event: string, params?: any): void
	{
		pilera.bridge.subscribeEvent(event, params);
	}

}
