import {IPileraCommonModel, PileraCommonModel} from "../pilera-common.model";
import * as lodash from "lodash";

export class CommunityInfo extends PileraCommonModel implements IPileraCommonModel
{
	constructor
	(
		public uuid: string
	)
	{
		super("CommunityInfo");
	}

	public getUUId(): string
	{
		return this.uuid;
	}

	public getPath(): string
	{
		let path = `/community/info/community/${this.uuid}`;
		return path;
	}


}


/**
 * A simple community - just the name and uuid
 *
 */
export class SimpleCommunity
{
	public active: boolean = false;
	public timeZone: string;
	public syncType: string;


	constructor
	(
		public uuid: string,
		public name: string
	)
	{}

}


/**
 * A collection of simple communities for a client
 *
 */
export class SimpleClientCommunityCollection extends PileraCommonModel implements IPileraCommonModel
{
	private simpleCommunities: Array<SimpleCommunity> = [];

	private communityUUIdIndex: Array<string> = [];

	constructor
	(
		public clientUUId: string
	)
	{
		super("ClientCommunityInfosView");
	}

	public addSimpleCommunity(simpleCommunity: SimpleCommunity): void
	{
		this.simpleCommunities.push(simpleCommunity);
		this.communityUUIdIndex.push(simpleCommunity.uuid);
	}

	public isCommunityInCollection(communityUUId: string): boolean
	{
		return ( this.communityUUIdIndex.indexOf(communityUUId) > -1 );

	}

	/**
	 * Returns a simple community object for passed community uuid.
	 * Returns null if no community found.
	 *
	 * @param communityUUId
	 */
	public getSimpleCommunity(communityUUId: string): SimpleCommunity
	{
		let results = this.getSimpleCommunities().filter((simpleCommunity: SimpleCommunity, index, array) =>
		{
			return (simpleCommunity.uuid === communityUUId);
		});

		if (results.length === 0) return null;

		return results[0];

	}

	/**
	* Returns the first Simple Community in the collection
	*
	*/
	public getFirstSimpleCommunity(): SimpleCommunity
	{
		return this.getSimpleCommunities()[0];
	}

	/**
	* Removes passed simple community from collection
	*
	* @param simpleCommunityToRemove
	*/
	public removeSimpleCommunity(simpleCommunityToRemove: SimpleCommunity): void
	{
		lodash.remove(this.simpleCommunities, (simpleCommunity) =>
		{
			return  ( simpleCommunity.uuid === simpleCommunityToRemove.uuid );
		});
	}



	public getSimpleCommunities(): Array<SimpleCommunity>
	{
		return this.simpleCommunities;
	}


	public getUUId(): string
	{
		return this.clientUUId;
	}

	public getPath(): string
	{
		let path = `/community/info/list/client/${this.clientUUId}`;
		return path;
	}


}
