import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";

import {Observable, throwError} from "rxjs";
import {NgRadio} from "ng-radio";
import {catchError} from "rxjs/operators";
import {PileraError} from "../error/pilera-error";
import {isNil} from "../components/component-helpers/pilera-component-helpers";

/**
 * Inspects all incoming responses and handles 403s specially
 */
@Injectable()
export class PileraModSecInterceptor implements HttpInterceptor {

	private report403sWithDialog: boolean = true;

	constructor(private radio: NgRadio)
	{
		/**
		 * By setting to false, this interceptor will not report via a dialog about 403s.
		 * Newer code, like tickets, handles this, so we don't want 2 dialogs to pop for 403s.
		 */
		this.radio.on("pileraapp:http:403handling:enable").subscribe((argEnable: boolean = true) =>
		{
			console.info("403 handling set to: " + argEnable);
			this.report403sWithDialog = argEnable;
		});
	}

	intercept(req: HttpRequest<any>, next: HttpHandler):
		Observable<HttpEvent<any>> {

		return next.handle(req).pipe(
			catchError((error) =>
			{
				// we only care about 403s. anything else we want the caller to handle
				// not all components handle responses from pcs well.. esp vendor, pets.. older ones
				// this ensures we inform the user about security issues all the time when 403s happen
				if (error.status === 403 && this.report403sWithDialog)
				{
					if (isNil(error.error.PcsRsError))
					{
						// in theory, we should always get a proper PcsRsError for 403s. but just in case we don't we handle here with a different userCode, etc
						let pileraError = new PileraError();
						pileraError.userCode = "PWS-0403";
						pileraError.userMessage = "We're sorry, our security system has detected a potential issue.";
						pileraError.userMessageDetail = "Uh oh, it looks like you may have tripped our security system.<br>" +
							"Don’t worry, if you feel that what you were doing was not a security issue then please reach out to Pilera support and we can look into it for you.";

						this.radio.cast("pileraapp:common:error:dialog:modal", pileraError);
					}
					else
					{
						this.radio.cast("pileraapp:common:error:dialog:modal", new PileraError(null, error.error.PcsRsError));
					}

					// informs the calling method that we handled the error and to cool it.
					return;
				}

				// not a 403, pass the error back to the caller
				return throwError(error);
			})
		);
	}
}
