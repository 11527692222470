import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {UserService} from "./shared/services/user.service";
import {Observable} from "rxjs";

@Injectable()
export class UserResolver  {
  constructor(private _userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>|Promise<any>|any
  {


    console.log("RESOLVER!!!!!!!!!!!! !!!!!!!!!!!!!!!!!!");

    return this._userService.user || this._userService.getUser();
  }
}
