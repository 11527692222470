import {Injectable} from "@angular/core";
import {PileraStringHelpers} from "../helpers/pilera-string.helpers";
import {isBlank, stripTags} from "underscore.string";
import {NgRadio} from "ng-radio";
import {PileraError} from "../../error/pilera-error";
import {PileraCommonErrorDialogService} from "../pilera-common-ui-tools/pilera-common-error-dialog.service";


@Injectable({
	providedIn: 'root'
})
export class ComponentHelpers {


  constructor(
  	private pileraStringHelpers: PileraStringHelpers,
    private pileraCommonErrorDialogService: PileraCommonErrorDialogService,
    private radio: NgRadio)
  {
    this.radio.on("pileraapp:common:error:dialog:modal").subscribe((pileraError: PileraError) =>
    {
        this.showErrorMessageModalBox(pileraError);
    });

	this.radio.on("pileraapp:https:operation:blocked:pretender").subscribe((argOperation: string) =>
	{
		this.showOperationBlockedPretender(argOperation);
	});
  }



  // TODO: silly this is needed.. look into ngIf and booleans more!
  public isTrue(toTest: any): boolean {
    return this.pileraStringHelpers.isTrue(toTest);
  }

    /**
     * Removes HTML tags from passed string
     *
     * @param {string} string
     * @returns {string}
     */
  public noHtml(string: string): string {
    return stripTags(string);
  }

  public getUriParameter(parameter: string): string {
    let query = window.location.search.substring(1);
    let vars = query.split("&");

    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      if (pair[0] === parameter) { return pair[1]; }
    }
    return null;
  }





	public onMouseMove(event: MouseEvent, grabberParams: GrabberParams): void
	{
		if (!grabberParams.isResizeGrabberHot) {
			return;
		}

		this.resizer(event.clientX - grabberParams.oldTreeColumnWidth, grabberParams);
		grabberParams.oldTreeColumnWidth = event.clientX;
	}

	public onMouseUp(event: MouseEvent, grabberParams: GrabberParams): void
	{
		grabberParams.isResizeGrabberHot = false;
	}

	public resizer(offsetX: number, grabberParams: GrabberParams): void
	{
		grabberParams.treeColumnWidth += offsetX;
	}

	public onMouseDown(event: MouseEvent, grabberParams: GrabberParams): void
	{

		// TODO: pain, could not use dot syntax gere
		let elClasses = event.target['className'];

		if ( elClasses.indexOf && (elClasses.indexOf("grabberBar") > -1 || elClasses.indexOf("grabberIcon") > -1))
		{
			grabberParams.isResizeGrabberHot = true;
			grabberParams.oldTreeColumnWidth = event.clientX;
			return;
		}

		grabberParams.isResizeGrabberHot = false;
	}

	/**
	 * Shows a modal error dialog with info from passed PileraError
	 *
	 * @param {PileraError} pileraError
	 */
	private showErrorMessageModalBox(pileraError: PileraError): void
	{
		if (pileraError.showInGlobalUI)
		{
			this.pileraCommonErrorDialogService.show(pileraError.getUITitle(), pileraError.getUIMessage());
		}
	}

	/**
	 * Shows an operation blocked due to pretender message.
	 *
	 * SOMEDAY: maybe put this someplace higher up, at the app level, not just at component level.
	 *
	 * @param {string} argOperation
	 * @returns {boolean}
	 */
	public showOperationBlockedPretender(argOperation: string): void
	{
		// PVP-7495 WARNING!! don't use pileraBsDialogService, it will fail to work in AWS, the entire ngx app will not load properly
		let pileraError = new PileraError();
		pileraError.userCode = "PWA-5455";
		pileraError.userMessage = `${argOperation} Blocked`;
		pileraError.userMessageDetail = `Sorry, this operation cannot be performed while in pretender mode.`;
		this.showErrorMessageModalBox(pileraError);
	}


}

/**
 * A helper method to help evaluate if passed value is undefined or null.
 * NOTE: if the value is an empty string, it will return false. Use isBlank() for that check.
 *
 * @param value
 * @returns {boolean}
 */
export function isNil(value: any): boolean {
	return value === undefined || value === null;
}

/**
 * Another helper to help evaluate if passed value has anything in it.
 * Returns true if it is null, undefined or a blank/empty string
 *
 * @param value
 * @returns {boolean}
 */
export function isNilOrBlank(value: any): boolean
{
	if (isNil(value) || isBlank(value))
	{
		return true;
	}

	return false;

}

export class GrabberParams
{
	constructor
	(
		public treeColumnWidth: number = window.innerWidth * .34,
		public oldTreeColumnWidth?: number,
		public isResizeGrabberHot?: boolean
	)
	{}
}
