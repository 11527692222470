/**
 * A client and community.
 * Use this class to simplify signatures and ensure you're passing the right values in the right order.
 */
export class ClientCommunityEntity
{
	constructor
	(
		public clientUUId: string,
		public communityUUId: string
	)
	{
	}
}
