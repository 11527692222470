export class PileraPcsRequestHelper {

	public cookies;
	constructor(
	) {this._getCookies()}

	/**
	 * Returns common request option arguments such as the pcs token for auth
	 *
	 * @returns {RequestOptionsArgs}
	 */
	public getOptionArgs(): object
	{
		return {};
	}

	private _getCookies() {
		this.cookies = new Map();
		document.cookie.split('; ').forEach(cookie => {
			let splits = cookie.split('=');
			this.cookies.set(splits[0], splits[1])
		})
	}


	/**
	 * Returns a pcs token for requests to pcs services
	 */
	public getPcsToken(): string
	{

		// tslint:disable-next-line:max-line-length
		//return "MzhmY2E0OTA2NTY4NDk2OTEyYTI1Y2U1N2RiYTFlMDAxMDZlNGNjMDg4YzhiZjQwNjAwNGY0ZjhhMmJlNjE1ZjBjOGFkY2E2ZjI4MjdlOWJhZDJkM2ZlNmJjNGVlYWQ3ZDMzNzUxNjQ5NDljNmQ3OTg1ZmFkMWUzODFiZDVhMjhjM2U2ZGUzYWFlNDUxMTgwYzkzZjI4YmFlNTQwOGNiNWEyMzIxNTJmZjI0MTcxNTAxOTkyNzAyMmFhYzIxZGJiNjNhN2UzMjRiZDI0ODlkZTVmZTAzYWNlZGQ2MWFkMThlZjJiMmU0MmFkZDJiNzg3OTFhYTI1OTUxMDc3ZTgwMDhhZWFkM2JjNWI5ZWIyNDY1MWM1OGVmYmIyZmUwNjg2ODczY2ZkMDVkNjZlZjExNWE5ZmQ1OTg2MGZmMzBlYzl8YTcwY2ZlMGI3MDNkY2ZkOWEzOWM2YTNjZWM4NjM2MTRkZDJhYWM5OGY5OGNiYzZkNDQ1OWFmODliN2FmNTI1YjZmOTI4NDRjOWM5Mzc1MTUzZjY2ZWRlZmQzNjlkNDFjYTlkNjg3YmQ5MGU3ZmUzOWIwYTg3NTRkMDMzMWE5ZGN8NjJjYTRkYmMyYjIwNDdhMTg5YjgyNmFhMjFkMDViMzE";


		return this.cookies.get('pcstoken');


	}


	private getHeaders(): Headers {
		const headers = new Headers();
		headers.append('x-pcs-auth-token', this.getPcsToken());
		headers.append('content-type', 'application/json');
		return headers;
	}


}

