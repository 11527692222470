/**
 * Provided core Pilera Model functionality support
 * More functionality to be refactored up into this class at some point
 */
import {isNil} from "../components/component-helpers/pilera-component-helpers";


export abstract class PileraCommonModel
{

	// older classes used a pattern where the client uuid was appending automatically to the end of urls
	// bad design on my [mike] part
	// newer models should turn this off and manage client uuids in urls themselves
	public useLegacyClientAppendingInPath: boolean = true;

	// this is used by the framework to know if an object is or extends the common model
	// we cannot take an object and ask for it's class or prototype on minified code.. so we do this here
	public isPileraCommonModel: boolean = true;

	// this is an optional param needed in some cases where the REST URI has an operation in it (add, remove).
	// not ideal, since the operation should be the verb/method.. but that's how it is
	// NOTE: if you find yourself using this with new PCS REST api's, ask the pcs dev why the method cannot be used
	// and keep the uri consistent.. let's try to clamp down on this anti pattern in the future
	public operation: string;

	// pcs or php
	public useHost: string = "pcs";

	constructor(private _wrappedClassName: string, useHost: string = "pcs")
	{
		this.useHost = useHost;
	}

	/*
	// TODO: not working useHost only taking effect when set in constructor for some reason
	public setUseHost(useHost: string): void
	{
		this.useHost = useHost;
	}
	*/

	/**
	 * Returns a json string of objects properties wrapped in a class name
	 * Follows a wrapping pattern we see in PCS often
	 *
	 * @returns {string}
	 * @deprecated - try not to use this more.. our Common Model patterns handle this better now
	 */
	public getJson(): string
	{

		const asString = JSON.stringify(this, this.getJsonReplacer);

		// not all classes may be wrapped, if not then we won't do here either
		if (isNil(this._wrappedClassName) || this._wrappedClassName === "")
		{
			return asString;
		}

		const json = `{"${this._wrappedClassName}": ${asString}}`;

		console.log("getJson returning:", json);

		return json;
	}

	/**
	 * Returns the wrapped class name PCS uses for this object
	 *
	 * @returns {string}
	 */
	public getWrappedClassName()
	{
		return this._wrappedClassName;
	}

	/**
	 * Sets the wrapped class name PCS uses for this object.
	 * You can also set this in the call to super when you initiate your child object
	 *
	 * @param {string} wrappedClassName
	 */
	public setWrappedClassName(wrappedClassName: string)
	{
		this._wrappedClassName = wrappedClassName;
	}


	/**
	 * Tell json stringify to omit certain properties from the string result
	 *
	 * @param key
	 * @param value
	 * @returns {any}
	 */
	private getJsonReplacer(key, value): any
	{
		if (key === "_wrappedClassName")
		{
			return undefined;
		}
		return value;
	}


	/**
	 * Returns the model properties as JSON
	 *
	 * Use this method only if you need to work with the JSON payload further.. see how this is used for examples
	 *
	 * NOTE: even though this method attempts to call getters.. I have seen it not work well for object that are children of others.
	 * Often the childs getters are not called, just the parents, etc.. Not sure why.
	 *
	 */
	public getAsJSON(clazz, omitProperties: Array<String>): Object
	{
		const jsonObj = Object.assign({}, clazz);

		const proto = Object.getPrototypeOf(clazz);
		for (const key of Object.getOwnPropertyNames(proto))
		{
			const desc = Object.getOwnPropertyDescriptor(proto, key);
			const hasGetter = desc && typeof desc.get === "function";
			if (hasGetter)
			{
				jsonObj[key] = desc.get();
			}
		}

		omitProperties.forEach((omit) =>
		{
			delete jsonObj[omit.toString()];
		});

		// TODO: impl scrubUnderscoredProperties

		return jsonObj;
	}

	/**
	 * Populates this models properties from passed response data.
	 * Let's us be dryer in our services.
	 *
	 * @param {Object} argResponseData
	 * @returns {this}
	 */
	public populate(argResponseData: Object): this
	{
		let data = argResponseData[this.getWrappedClassName()];
		return Object.assign(this, data);
	}








}





/**
 * An interface Pilera Common Models should implement
 */
export interface IPileraCommonModel
{
	// TODO: need to impl getIdentifier(): any - UUId terminology too strict

	useLegacyClientAppendingInPath: boolean;

	getUUId(): any;

	getPath(method?: string): string;
}
