import { Component, Input, OnInit } from '@angular/core';
import { GrowlService } from './growl.service';

@Component({
    selector: 'app-growl-alert',
    template: `
        <div *ngIf="!hideGrowl" class="growl alert alert-{{ alert.type }}">
            <span (click)="hideGrowl = true" class="close" aria-label="close">&times;</span>
            <span class="text-{{ alert.type }}">{{ alert.message }}</span>
        </div>
    `,
    styles: [`
        .growl {
            left: 0;
            top: 0;
            right: 0;
            opacity: .99;
            position: absolute;
        }
    `]
})
export class GrowlComponent implements OnInit {

    @Input() ttl: number;

    public alert;
    public hideGrowl: boolean = true;

    constructor(private _growlService: GrowlService) {
        if (this.ttl) {
            this._growlService.options.ttl = this.ttl;
        }
    }

    ngOnInit() {
        this._growlService.growlSubject
            .subscribe(alert => this._displayAlert(alert));
    }

    private _displayAlert(alert) {
        let ttl = (alert.options && alert.options.ttl) ? alert.options.ttl : this._growlService.options.ttl;

        this.hideGrowl = false;
        this.alert = alert;

        if (ttl) {
            setTimeout(() => this.hideGrowl = true, ttl);
        }
    }
}
