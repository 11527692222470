import {Injectable} from "@angular/core";

import {environment} from "../../../environments/environment.local";
import {Observable} from "rxjs";
import {PileraPcsRequestHelper} from "./pilera-pcs-request.helper";
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from "@angular/common/http";

import * as lodash from "lodash";

import {PileraCommonModel} from "../model/pilera-common.model";
import {isNil, isNilOrBlank} from "../components/component-helpers/pilera-component-helpers";
import { catchError, map, tap } from "rxjs/operators";

/**
 * This service is to be used for all http requests regarding PCS.
 * Applies common PCS headers
 */
@Injectable()
export class PileraHttpService
{
	public cookies: Map<string, string>;
	private _pcsHost: string;
	private phpHost: string;

	private _url: string;
	private _indexUrl: string;

	private _pileraPcsRequestHelper: PileraPcsRequestHelper = new PileraPcsRequestHelper();

	/**
	 * Constructor.
	 *
	 * @param {HttpClient} httpClient
	 */
	constructor(private httpClient: HttpClient)
	{
		this.loadCookies();
		this.initializeHosts();
	}

	/**
	 * Returns the url.
	 *
	 * @returns {string}
	 */
	get url()
	{
		return this._url;
	}

	/**
	 * Returns the index url.
	 *
	 * @returns {string}
	 */
	get indexUrl()
	{
		return this._indexUrl;
	}

	/**
	 * Sets the pcs host.
	 *
	 * @param {string} argPcsHost
	 */
	set pcsHost(argPcsHost: string)
	{
		this._pcsHost = argPcsHost;
	}

	/**
	 * Returns the pcs host.
	 *
	 * @returns {string}
	 */
	get pcsHost(): string
	{
		return this._pcsHost;
	}


	/**
	 * Returns the host with any additional needed path
	 *
	 * @returns {string}
	 */
	get hostWithRootPath(): string
	{
		if (!isNilOrBlank(this.pcsHost))
		{
			return `${this.pcsHost}/pcs/rest`;
		}
		// php already has the path
		return this.indexUrl;
	}

	/**
	 * Evaluates the uri that the app is operating in and adjusts the
	 * environment.host file to comply- needed to support validation 1, 2 and 3
	 *
	 * After the host is proper, this method then sets the final host endpoint
	 *
	 */
	private initializeHosts(): void
	{
		// validation is special, it uses one node/ngx server, but can talk to two different PCS endpoints

		if (environment.host === "validation")
		{
			let environmentNumber = "";

			console.log("ENVIRONMENT HOST CHECK: this looks like validation");
			let browserUrl = window.location.href;

			if (browserUrl.indexOf("validation1") > -1)
			{
				environment.host = "validation1";
				environmentNumber = "1";
			}
			else if (browserUrl.indexOf("validation2") > -1)
			{
				environment.host = "validation2";
				environmentNumber = "2";
			}
			else if (browserUrl.indexOf("validation3") > -1)
			{
				environment.host = "validation3";
				environmentNumber = "3";
			}
			else
			{
				throw "Cannot determine validation environment.";
			}

			console.log(`ENVIRONMENT HOST CHECK: the host will be: ${environment.host}`);

			this.phpHost = environment.phpUrl.replace("validation", "validation" + environmentNumber);
		}
		else
		{
			// note, we call this host, but it has /index.php in it also
			this.phpHost = environment.phpUrl;
		}

		this.pcsHost = `https://pcs${environment.host ? "-" + environment.host : ""}.pilera.com`;


	}

	// TODO: fix this to be more helpful, will break on equals sign
	private loadCookies()
	{
		this.cookies = new Map();
		document.cookie.split("; ").forEach(cookie =>
		{
			let splits = cookie.split("=");
			this.cookies.set(splits[0], splits[1]);
		});
	}

	/**
	 * Helper, sets url with clientUuid
	 * @param clientUuid
	 * @private
	 *
	 * @deprecated - this method is too specific to vendor and should not be used for future impl
	 *
	 */
	public setUrl(clientUuid)
	{

		// TODO: whoa... this should not have "vendor" context in it, since this service is to be generic
		// TODO: or for that matter, anything after /pcs/rest/

		this._url = `/pcs/rest/vendors/client/${clientUuid}`;
		this._indexUrl = `/pcs/rest/vendors/list/index/client/${clientUuid}`;
	}


	/**
	 * Returns the headers for the http request
	 *
	 * @param {boolean} isForm
	 * @returns {HttpHeaders}
	 */
	private getHeaders(isForm: boolean = false, isMultipart: boolean = false): HttpHeaders
	{
		let contentType = (isForm) ? "application/x-www-form-urlencoded" : "application/json";

		// jeesh, this object is immutable, so we MUST chain the sets, like we have here
		// https://stackoverflow.com/questions/45286764/angular-httpclient-doesnt-send-header

		if (isMultipart)
		{
			return new HttpHeaders({"x-pcs-auth-token": this._pileraPcsRequestHelper.getPcsToken()});
		}

		return new HttpHeaders({"Content-Type": contentType, "x-pcs-auth-token": this._pileraPcsRequestHelper.getPcsToken()});

	}


	/**
	 * Build the final URL for the request
	 * If the URL does not look complete, it assumes we're going to PCS and prepends the PCS root
	 *
	 * @param url - a url string, or a PileraCommonModel object
	 *
	 * TODO: clarify this method further.. don't like the argument, etc
	 */
	private getFinalUrl(urlOrPileraCommonModelObject: any): string
	{
		let path = urlOrPileraCommonModelObject;

		let finalHost: string = this.pcsHost;

		if (lodash.isObject(urlOrPileraCommonModelObject))
		{
			// yeah, we assume it's a common model passed,
			urlOrPileraCommonModelObject = urlOrPileraCommonModelObject as PileraCommonModel;

			if (!isNil(urlOrPileraCommonModelObject.isPileraCommonModel) && urlOrPileraCommonModelObject.isPileraCommonModel)
			{
				if (urlOrPileraCommonModelObject.useHost === "pcs")
				{
					path = "/pcs/rest" + urlOrPileraCommonModelObject.getPath();
					finalHost = this.pcsHost;
				}
				else if (urlOrPileraCommonModelObject.useHost === "php")
				{
					// note php host has /index.php already in it
					path = urlOrPileraCommonModelObject.getPath();
					finalHost = this.phpHost;
				}
				else
				{
					throw new Error("Passed model has not specified to use pcs or php for host.");
				}

			}
		}

		if (path.indexOf("https://") !== -1)
		{
			return path;
		}

		return finalHost + path;

	}


	/**
	 * Wrapped GET request
	 * @param urlOrPileraCommonModelObject
	 * @returns {Observable<Response>}
	 */
	public get(urlOrPileraCommonModelObject: any)
	{
		return this.httpClient.get(this.getFinalUrl(urlOrPileraCommonModelObject),
			{headers: this.getHeaders(), withCredentials: this.isUseCredentials(urlOrPileraCommonModelObject)})
			.map((response) => response);
	}


	public postForm(urlOrPileraCommonModelObject: any, body: HttpParams): Observable<any>
	{
		return this.post(urlOrPileraCommonModelObject, body, true);
	}

	public postMultipart(urlOrPileraCommonModelObject: any, body: any): Observable<any>
	{
		return this.post(urlOrPileraCommonModelObject, body, true, true);
	}

	/**
	 * Wrapped POST request
	 * @param url
	 * @param body
	 * @returns {Observable<Response>}
	 */
	public post(urlOrPileraCommonModelObject: any, body: any = null, isForm: boolean = false, isMultipart:boolean = false): Observable<any>
	{
		let bodyBuild = body;

		// TODO: yuk
		let pileraCommonModel = urlOrPileraCommonModelObject as PileraCommonModel;

		if (!isForm && lodash.isObject(urlOrPileraCommonModelObject) && !isNil(pileraCommonModel.isPileraCommonModel) && pileraCommonModel.isPileraCommonModel)
		{
			bodyBuild = {[pileraCommonModel.getWrappedClassName()]: urlOrPileraCommonModelObject};
		}
		else if (!isForm && typeof body === "object")
		{
			bodyBuild = JSON.stringify(body);
		}
		else
		{
			bodyBuild = body;
		}

		return this.httpClient.post(this.getFinalUrl(urlOrPileraCommonModelObject), bodyBuild, {headers: this.getHeaders(isForm, isMultipart), withCredentials: this.isUseCredentials(urlOrPileraCommonModelObject)})
			.map((response) => response);
	}


	public putForm(urlOrPileraCommonModelObject: any, body: HttpParams): Observable<any>
	{
		return this.put(urlOrPileraCommonModelObject, body.toString(), true);
	}


	/**
	 * Wrapped PUT request
	 * @param url
	 * @param body
	 * @returns {Observable<Response>}
	 */
	public put(urlOrPileraCommonModelObject: any, body: any = null, isForm: boolean = false): Observable<any>
	{

		let bodyBuild = body;

		let pileraCommonModel = urlOrPileraCommonModelObject as PileraCommonModel;

		// TODO: look into hiding _ properties
		// https://stackoverflow.com/questions/41685082/how-to-ignore-properties-sent-via-http

		if (!isForm && lodash.isObject(urlOrPileraCommonModelObject) && !isNil(pileraCommonModel.isPileraCommonModel) && pileraCommonModel.isPileraCommonModel)
		{
			bodyBuild = {[pileraCommonModel.getWrappedClassName()]: urlOrPileraCommonModelObject};
		}
		else if (!isForm && typeof body === "object")
		{
			bodyBuild = JSON.stringify(body);
		}
		return this.httpClient.put(this.getFinalUrl(urlOrPileraCommonModelObject), bodyBuild, {headers: this.getHeaders(isForm), withCredentials: this.isUseCredentials(urlOrPileraCommonModelObject)})
			.map((response) => response);
	}

	/**
	 * Wrapped DELETE request
	 * @param urlOrPileraCommonModelObject
	 * @param body {Object}
	 * @returns {Observable<Response>}
	 */
	public delete(urlOrPileraCommonModelObject: any, body?: any)
	{
		let bodyBuild = body;

		let pileraCommonModel = urlOrPileraCommonModelObject as PileraCommonModel;

		// TODO: look into hiding _ properties
		// https://stackoverflow.com/questions/41685082/how-to-ignore-properties-sent-via-http

		if (lodash.isObject(urlOrPileraCommonModelObject) && !isNil(pileraCommonModel.isPileraCommonModel) && pileraCommonModel.isPileraCommonModel)
		{
			bodyBuild = {[pileraCommonModel.getWrappedClassName()]: urlOrPileraCommonModelObject};
		}
		else if (typeof body === "object")
		{
			bodyBuild = JSON.stringify(body);
		}

		let requestOptions = {headers: this.getHeaders(), withCredentials: this.isUseCredentials(urlOrPileraCommonModelObject), body: {}};

		if (!isNil(bodyBuild))
		{
			requestOptions.body = bodyBuild;
		}


		// body && Object.assign(requestOptions, {bodyBuild});

		return this.httpClient.delete(this.getFinalUrl(urlOrPileraCommonModelObject), requestOptions)
			.map((response) => response);
	}


	/**
	 * Returns true if the request should send credentials - needed when talking to PHP
	 *
	 * @param urlOrPileraCommonModelObject
	 * @returns {boolean}
	 */
	private isUseCredentials(urlOrPileraCommonModelObject: any): boolean
	{
		if (isNil(urlOrPileraCommonModelObject)) return false;
		return (urlOrPileraCommonModelObject.useHost === "php") ? true : false;
	}

	/**
	 * Returns a full HttpResponse object containing a blob when we need to download a file
	 * @param urlOrPileraCommonModelObject
	 * @param body
	 * @returns {HttpResponse<Blob>}
	 */
	download(urlOrPileraCommonModelObject: any): Observable<HttpResponse<Blob>>
	{
		let bodyBuild = null;

		let pileraCommonModel = urlOrPileraCommonModelObject as PileraCommonModel;

		if (lodash.isObject(urlOrPileraCommonModelObject) && !isNil(pileraCommonModel.isPileraCommonModel) && pileraCommonModel.isPileraCommonModel)
		{
			bodyBuild = {[pileraCommonModel.getWrappedClassName()]: urlOrPileraCommonModelObject};
		}
		else
		{
			bodyBuild = JSON.stringify(urlOrPileraCommonModelObject);
		}

		return this.httpClient.post(
			this.getFinalUrl(urlOrPileraCommonModelObject),
			bodyBuild,
			{
				responseType: 'blob',
				headers: this.getHeaders(false, false),
				withCredentials: this.isUseCredentials(urlOrPileraCommonModelObject),
				observe: 'response' // Need the full response with headers
			}
		);
	}
}
