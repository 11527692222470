/**
 * The portal type for the application, such as PILERA or COMMHUB
 *
 */
export enum PortalType
{
	PILERA = 0,
	COMMHUB = 1,
	RFPONLY = 2
}
