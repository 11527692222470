import {Injectable} from "@angular/core";
import {isNil, isNilOrBlank} from "../component-helpers/pilera-component-helpers";

@Injectable({
	providedIn: 'root'
})
export class PileraStringHelpers {

    constructor() {}

    // TODO: silly this is needed.. look into ngIf and booleans more!
    public isTrue(toTest: any): boolean
    {
        if (isNil(toTest)) return false;

        return (toTest === true || toTest === "true");
    }

	/**
	 * Returns passed string if not empty, with additional/optional prefix and suffix .
	 * An optional else is also supported.
	 *
	 * @param {string} argString
	 * @param {string} argPrefix
	 * @param {string} argSuffix
	 * @param {string} argElse
	 * @returns {string}
	 */
	public returnIfNotEmptyElse(argString: string, argElse?: string, argPrefix?: string, argSuffix?: string): string
	{
		if (isNilOrBlank(argString))
		{
			return argElse;
		}

		argPrefix = (isNilOrBlank(argPrefix)) ? "" : `${argPrefix} `;
		argSuffix = (isNilOrBlank(argSuffix)) ? "" : ` ${argSuffix}`;

		return `${argPrefix}${argString}${argSuffix}`.trim();

	}

	/**
	 * Takes passed string and "adjusts" if for non ascii characters.
	 * It will convert quotes, apost, dashes and elipses to an ascii "equivalent" and then strip others.
	 * Use this when you know the string will break something if it contains non-ascii.
	 *
	 * @param argString
	 * @returns {*}
	 */
	public getCleansedOfNonAscii(argString): string
{
	return argString
		.replace(/[\u2018\u2019]/g, "'")
		.replace(/[\u201C\u201D]/g, '"')
		.replace(/[\u2013\u2014]/g, '-')
		.replace(/[\u2026]/g, '...')
		.replace(/[^\x20-\x7E]/g, '');
}


}
