import {Injectable} from "@angular/core";
import {ReplaySubject} from "rxjs";

@Injectable()
export class GrowlService {

    public growlSubject = new ReplaySubject(1);
    public types = ['warning', 'info', 'danger', 'success'];
    public options;

    constructor() {
        this.options = {
            customClass: '',
            ttl: 10000
        };
    }

    public showGrowl(alert) {
        if (typeof alert !== 'object') {
            alert = {type: 'danger', message: alert};
        } else if (this.types.indexOf(alert.type) < 0) {
            throw new Error('Growl type unavailable, please use one of the available growl types: "warning", "info", "danger", "success"');
        }

        this.growlSubject.next(alert);
    }

}
