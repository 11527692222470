import { isBlank } from "underscore.string";
import * as lodash from "lodash";
import {HttpErrorResponse} from "@angular/common/http";
import {isNil} from "../components/component-helpers/pilera-component-helpers";

/**
 * A common Pilera Error
 * This is the preferred error object to instantiate for all error handling.
 * It can be consumed by a common error dialog if needed.
 *
 * Can also be instantiated from a PcsRsError in a response object
 *
 */
export class PileraError
{
	private defaultUserMessage = "Please try back later.";

	public showInGlobalUI: boolean = true;

	/**
	 *
	 * @param {Response} response
	 * @param {string} userCode
	 * @param {string} exceptionType
	 * @param {string} detailMessage
	 * @param {string} userMessage
	 * @param {string} userMessageDetail
	 */
	constructor(
		private response?: HttpErrorResponse,
		private pcsRsError?: Object,
		public userCode: string = "PWA-7744",
		public exceptionType?: string,
		public detailMessage?: string,
		public userMessage: string = "Sorry, an error has occurred",
		public userMessageDetail: string = "Please try back later."
	)
	{
		this.init();
	}

	private init(): void
	{
		if (lodash.isObject(this.pcsRsError))
		{
			this.populateFromPcsRsObject(this.pcsRsError);
		}
		if (!isNil(this.response) && !isNil(this.response.error))
		{
			// we have a response object that we can populate props from if it's got a PCS error in it

			let pcsRsError = this.response.error["PcsRsError"];
			let phpRsError = this.response.error["PhpRsError"];

			if (isNil(pcsRsError))
			{
				if (isNil(phpRsError))
				{
					// no pcs error, so we set some defaults

					this.userCode = "(PWA-0503)";
					this.exceptionType = "";
					this.detailMessage = "";
					this.userMessage = "Sorry, an error has occurred";
					this.userMessageDetail = "Please try back later.";
				}
				else
				{
					this.populateFromPcsRsObject(phpRsError);
				}
			}
			else
			{
				this.populateFromPcsRsObject(pcsRsError);
			}
		} else {
			this.userCode = "PWA-0118";
			this.exceptionType = "";
			this.detailMessage = "";
			this.userMessage = "Sorry, an error has occurred";
			this.userMessageDetail = "Please try back later.";

			console.error(this.response);
		}
	}

	/**
	 * Populates object from passed pcsRsError object
	 *
	 * @param pcsRsError
	 */
	public populateFromPcsRsObject(pcsRsError: any): void
	{
		this.userCode = pcsRsError.userCode;
		this.exceptionType = pcsRsError.exceptionType;
		this.detailMessage = pcsRsError.detailMessage;

		if (!isBlank(pcsRsError.userMessage))
		{
			this.userMessage = pcsRsError.userMessage;
		}

		if (!isBlank(pcsRsError.userMessageDetail))
		{
			this.userMessageDetail = pcsRsError.userMessageDetail;
		}
	}

	/**
	 * Returns a title for UI display.
	 * Builds from various properties in this object
	 *
	 * @returns {string}
	 */
	public getUITitle(): string
	{
		let title = `(${this.userCode}) ${this.userMessage}`;

		// TODO: look out for PWS-2001 no valid session, user messages not ideal

		return title;

	}

	/**
	 * Returns a message for UI display
	 * Protects from no specific message, and uses default in that case
	 *
	 * @returns {string}
	 */
	public getUIMessage(): string
	{
		// TODO: work on a way to handle messages we don't like in the ui

		let message = (isBlank(this.userMessageDetail)) ? this.defaultUserMessage : this.userMessageDetail;

		return message;

	}


}
