import { Component, Input } from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
//import { BsModalRef } from 'ngx-bootstrap/modal/modal-options.class';

@Component({
	selector: 'app-confirm',
	template: `

		<div class="modal-header">
			<h4 class="modal-title pull-left" [innerHTML]="title"></h4>

			<button type="button" class="close float-right" aria-label="Close" (click)="bsModalRef.hide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>

		<div class="modal-body" [innerHTML]="message"></div>

		<div class="modal-footer">
			<button type="button" class="btn btn-success" (click)="bsModalRef.hide()" translate="ok">Okay</button>
		</div>`,


	styles: [`

	/*
	TODO: lookout, may polute others.. find a way to not do deep
	 */

	:host::ng-deep.modal-content
	{
		width: 70%;
		margin-left: auto;
		margin-right: auto;
	}


	`],

	providers: [BsModalService]
})
export class PileraCommonErrorDialogComponent {
	@Input() title: string = "DEFAULT A";
	@Input() message: string = "DEFAULT B";

	constructor(public bsModalRef: BsModalRef) { }

	public clickOk() {
		console.log("Click ok...");
	}
}


