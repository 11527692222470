"use strict";

import * as lodash from "lodash";

// TODO: the validator component we are using was not enforcing a "dot" in the email address
// Pilera would not want to support such an address, albeit it is legal
// this pattern was to help with such validation.. BUT, i could not manage to get the constant passed into the "patterns" attribute
// so needs more work - So NOT USED YET
//export const PATTERN_VALUE_EMAIL = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$';


// TODO: also NOT used yet. could not get to work
/*
 export const DATE_PICKER_OPTIONS: INgxMyDpOptions =
 {
 dateFormat: 'mm/dd/yyyy'
 };
 */

// TODO: looks like no longer used?
export const vendorCategoriesMasterList = ["Asphalt", "Building Repair", "Concrete", "Custodial", "Dumpster", "Electrical",
	"Engineering", "Entry System", "Equipment", "Fire/Security", "Garbage", "General Repair", "Irrigation",
	"Landscaping", "Legal/Attorney", "Locksmith", "Mobile Entry", "Other", "Painting", "Pests", "Plumbing", "Pool/Spa", "Remediation",
	"Roofing", "Snow Removal", "Streets", "Task", "Telephones", "Towing", "Water Removal"];


export const FILE_LIBRARY_MAX_SIZE_MB = 25;


export const CLIENT_PILERA_BLUEPRINT_CLIENT_UUID = "0a8f4e179c84caf532c9b0cdc2ddd7c6";
export const CLIENT_PILERA_BLUEPRINT_CLIENT_NAME = "Pilera Blueprint Client";


/*
 YO YO YO! Adding extensions? Make sure to also adjust:
 FileLibraryObject.getFileTypeName()
 FileLibraryUiHelpers.getIconClasses()
 */
export const FILE_LIBRARY_EXTENSIONS_SUPPORTED = ["bmp", "doc", "docx", "csv", "gif", "jpg", "jpeg", "pdf", "pdfx", "png", "ppt", "pptx", "rtf", "tif", "tiff", "txt", "xls", "xlsx", "zip", "dwg"];

/**
 * file extensions we could show in a browser (using the html img tag)
 *
 * @type {string[]}
 */
export const EXENTIONS_IMAGE_BROWSER_SUPPORTED = ["gif", "jpg", "jpeg", "png"];

// TODO: make this have an interface so we don't have to do any
// used for managing state of buttons.. someday, i want to make a button component that abstracts this further
export const BUTTON_STATE: any =
	{
		notSending: 0,
		sending: 1,
		sent: 2,
		error: 3
	};

export const FULL_CALENDAR_LICENSE = "0331426259-fcs-1699200820";


// see Current Roles & Acceptable Mappings To User Types
// https://pilera.atlassian.net/wiki/spaces/PF/pages/846430209/Current+Roles+Acceptable+Mappings+To+User+Types

// all community scoped roles
export const ROLES_COMMUNITY_ALL_CODES = ["AnnouncementMonitor", "BoardMember", "CommunityAdmin", "CommunityContact",
	"CommunityFileManager", "CommunityManager", "MessageAdmin", "MessageMonitor", "SendDocumentMonitor", "TaskAdmin"];

// all client scoped roles
export const ROLES_CLIENT_ALL_CODES = ["ClientFileManager", "CompanyAdmin", "TemplateAdmin", "VendorAdmin"];

// role codes
export const ROLES_ALL_CODES = lodash.merge(ROLES_COMMUNITY_ALL_CODES, ROLES_CLIENT_ALL_CODES);

// only roles a resident is allowed to have
export const ROLES_RESIDENTS_ALLOWED = ["AnnouncementMonitor", "BoardMember", "CommunityContact", "MessageMonitor", "SendDocumentMonitor", "DiscussionGroupModerator", "ArchitectureChangeRequestCommitteeMember"];

// roles a resident is not allowed to have
export const ROLES_RESIDENTS_NOT_ALLOWED = lodash.differenceWith(ROLES_ALL_CODES, ROLES_RESIDENTS_ALLOWED, lodash.isEqual);

export const ROLE_COMMUNITY_FILE_MANAGER_UUID = "6d7aa339717840fb8b6a9b32f6a5f50f";

export const CALENDAR_TYPE_EVENT_ID = 1;
export const CALENDAR_TYPE_CUSTOM_ID = 2;
export const CALENDAR_TYPE_NAMES = {
	"1": "Event",
	"2": "Custom"
};
export const CALENDAR_TYPES = [
	{
		"id": 1,
		"name": "Event"
	},
	{
		"id": 2,
		"name": "Custom"
	}
];
export const MAX_CALENDAR_COUNT = 258;


/**
 * Configuration for CKEditor 5 toolbar items, etc
 * See https://pilera.atlassian.net/wiki/spaces/PD/pages/2160525313/Angular+NGX+CkEditor+CkFinder+Notes+-+WYSIWYG+RTE
 *
 * @type {any[]}
 */
export const CKEDITOR_5_CONFIG = [];

// https://ckeditor.com/docs/ckeditor5/latest/features/general-html-support.html
CKEDITOR_5_CONFIG["htmlSupport"] =
	{
		allow:
			[
				{
					name: "div",
					styles: true,
					classes: true,
					attributes: ["id", "rel"]
				},
				{
					name: "img",
					styles: true,
					classes: true,
					attributes: ["id", "rel"]
				},
				{
					name: "section",
					styles: true,
					classes: true,
					attributes: ["id", "rel"]
				},
				{
					name: "article",
					styles: true,
					classes: true,
					attributes: ["id", "rel"]
				},
				{
					name: "button",
					styles: true,
					classes: true,
					attributes: ["id", "rel"]
				},
				{
					name: "a",
					styles: true,
					classes: true,
					attributes: ["id", "rel", "target"]
				},
				{
					name: "span",
					styles: true,
					classes: true,
					attributes: ["id", "rel"]
				}
			]
	};


CKEDITOR_5_CONFIG["toolbar"] =
	{
		items:
			[
				"sourceEditing",
				"removeFormat",
				"undo",
				"redo",
				"|",
				"heading",
				"|",
				"findAndReplace",
				"|",
				"insertTable",
				"|",
				"CKFinder",
				"|",
				"link",
				"-",
				"bold",
				"italic",
				"underline",
				"strikethrough",
				"subscript",
				"superscript",
				"specialCharacters",
				"horizontalLine",
				"|",
				"numberedList",
				"bulletedList",
				"|",
				"outdent",
				"indent",
				"|",
				"blockQuote",
				"alignment",
				"-",
				"fontFamily",
				"fontSize",
				"fontBackgroundColor",
				"fontColor",
				"highlight",
				"htmlEmbed"
			],
		shouldNotGroupWhenFull: true,
		language: "en"
	};

export const UI_NEW_BADGE_HTML: string = `<span class="badge badge-warning ml-1">NEW</span></label>`;

// https://ckeditor.com/docs/ckeditor5/latest/api/module_image_image-ImageConfig.html#member-resizeOptions
// this works, if we ever need it
/*
 CKEDITOR_5_CONFIG['image'] =
 {
 toolbar:
 [
 "imageTextAlternative",
 "imageStyle:inline",
 "imageStyle:block",
 "imageStyle:side",
 "linkImage",

 'resizeImage:50',
 'resizeImage:75',
 'resizeImage:original'


 ],
 resizeOptions:
 [
 {
 name: 'resizeImage:original',
 value: null,
 icon: 'original'
 },
 {
 name: 'resizeImage:50',
 value: '50',
 icon: 'medium'
 },
 {
 name: 'resizeImage:75',
 value: '75',
 icon: 'large'
 }
 ]
 }
 */

CKEDITOR_5_CONFIG["ckfinder"] =
	{
		uploadUrl: "https://mike.pilera.com/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json",
		openerMethod: "popup"
	};

/*
 PVP-7113
 CKEDITOR_5_CONFIG['wproofreader'] = {
 autoSearch: false,
 enableGrammar: false,
 serviceId: 'pMwghrDJyYtb7Ym',
 srcUrl: 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js',
 };
 */

export const CKEDITOR_STYLES = `
	/* ckeditor5-basic-styles/theme/code.css */
	code {
		background-color: hsla(0, 0%, 78%, 0.3);
		padding: .15em;
		border-radius: 2px;
	}
	/* ckeditor5-block-quote/theme/blockquote.css */
	blockquote {
		overflow: hidden;
		padding-right: 1.5em;
		padding-left: 1.5em;
		margin-left: 0;
		margin-right: 0;
		font-style: italic;
		border-left: solid 5px hsl(0, 0%, 80%);
	}
	/* ckeditor5-block-quote/theme/blockquote.css */
	.ck-content[dir="rtl"] blockquote {
		border-left: 0;
		border-right: solid 5px hsl(0, 0%, 80%);
	}
	/* ckeditor5-highlight/theme/highlight.css */
	.marker-yellow {
		background-color: hsl(60, 97%, 73%);
	}
	/* ckeditor5-highlight/theme/highlight.css */
	.marker-green {
		background-color: hsl(120, 93%, 68%);
	}
	/* ckeditor5-highlight/theme/highlight.css */
	.marker-pink {
		background-color: hsl(345, 96%, 73%);
	}
	/* ckeditor5-highlight/theme/highlight.css */
	.marker-blue {
		background-color: hsl(201, 97%, 72%);
	}
	/* ckeditor5-highlight/theme/highlight.css */
	.pen-red {
		color: hsl(0, 85%, 49%);
		background-color: transparent;
	}
	/* ckeditor5-highlight/theme/highlight.css */
	.pen-green {
		color: hsl(112, 100%, 27%);
		background-color: transparent;
	}
	/* ckeditor5-font/theme/fontsize.css */
	.text-tiny {
		font-size: .7em;
	}
	/* ckeditor5-font/theme/fontsize.css */
	.text-small {
		font-size: .85em;
	}
	/* ckeditor5-font/theme/fontsize.css */
	.text-big {
		font-size: 1.4em;
	}
	/* ckeditor5-font/theme/fontsize.css */
	.text-huge {
		font-size: 1.8em;
	}
	/* ckeditor5-image/theme/imagecaption.css */
	.image > figcaption {
		display: table-caption;
		caption-side: bottom;
		word-break: break-word;
		color: hsl(0, 0%, 20%);
		background-color: hsl(0, 0%, 97%);
		padding: .6em;
		font-size: .75em;
		outline-offset: -1px;
	}
	/* ckeditor5-image/theme/imageresize.css */
	.image.image_resized {
		max-width: 100%;
		display: block;
		box-sizing: border-box;
	}
	/* ckeditor5-image/theme/imageresize.css */
	.image.image_resized img {
		width: 100%;
	}
	/* ckeditor5-image/theme/imageresize.css */
	.image.image_resized > figcaption {
		display: block;
	}
	/* ckeditor5-image/theme/image.css */
	.image {
		display: table;
		clear: both;
		text-align: center;
		margin: 0.9em auto;
		min-width: 50px;
	}
	/* ckeditor5-image/theme/image.css */
	.image img {
		display: block;
		margin: 0 auto;
		max-width: 100%;
		min-width: 100%;
	}
	/* ckeditor5-image/theme/image.css */
	.image-inline {
		/*
		 * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
		 * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
		 * This strange behavior does not happen with inline-flex.
		 */
		display: inline-flex;
		max-width: 100%;
		align-items: flex-start;
	}
	/* ckeditor5-image/theme/image.css */
	.image-inline picture {
		display: flex;
	}
	/* ckeditor5-image/theme/image.css */
	.image-inline picture,
	.image-inline img {
		flex-grow: 1;
		flex-shrink: 1;
		max-width: 100%;
	}
	/* ckeditor5-image/theme/imagestyle.css */
	.image-style-block-align-left,
	.image-style-block-align-right {
		max-width: calc(100% - 1.5em);
	}
	/* ckeditor5-image/theme/imagestyle.css */
	.image-style-align-left,
	.image-style-align-right {
		clear: none;
	}
	/* ckeditor5-image/theme/imagestyle.css */
	.image-style-side {
		float: right;
		margin-left: 1.5em;
		max-width: 50%;
	}
	/* ckeditor5-image/theme/imagestyle.css */
	.image-style-align-left {
		float: left;
		margin-right: 1.5em;
	}
	/* ckeditor5-image/theme/imagestyle.css */
	.image-style-align-center {
		margin-left: auto;
		margin-right: auto;
	}
	/* ckeditor5-image/theme/imagestyle.css */
	.image-style-align-right {
		float: right;
		margin-left: 1.5em;
	}
	/* ckeditor5-image/theme/imagestyle.css */
	.image-style-block-align-right {
		margin-right: 0;
		margin-left: auto;
	}
	/* ckeditor5-image/theme/imagestyle.css */
	.image-style-block-align-left {
		margin-left: 0;
		margin-right: auto;
	}
	/* ckeditor5-image/theme/imagestyle.css */
	p + .image-style-align-left,
	p + .image-style-align-right,
	p + .image-style-side {
		margin-top: 0;
	}
	/* ckeditor5-image/theme/imagestyle.css */
	.image-inline.image-style-align-left,
	.image-inline.image-style-align-right {
		margin-top: 0.75em;
		margin-bottom: 0.75em;
	}
	/* ckeditor5-image/theme/imagestyle.css */
	.image-inline.image-style-align-left {
		margin-right: 0.75em;
	}
	/* ckeditor5-image/theme/imagestyle.css */
	.image-inline.image-style-align-right {
		margin-left: 0.75em;
	}
	/* ckeditor5-language/theme/language.css */
	span[lang] {
		font-style: italic;
	}
	/* ckeditor5-list/theme/todolist.css */
	.todo-list {
		list-style: none;
	}
	/* ckeditor5-list/theme/todolist.css */
	.todo-list li {
		margin-bottom: 5px;
	}
	/* ckeditor5-list/theme/todolist.css */
	.todo-list li .todo-list {
		margin-top: 5px;
	}
	/* ckeditor5-list/theme/todolist.css */
	.todo-list .todo-list__label > input {
		-webkit-appearance: none;
		display: inline-block;
		position: relative;
		width: 16px;
		height: 16px;
		vertical-align: middle;
		border: 0;
		left: -25px;
		margin-right: -15px;
		right: 0;
		margin-left: 0;
	}
	/* ckeditor5-list/theme/todolist.css */
	.todo-list .todo-list__label > input::before {
		display: block;
		position: absolute;
		box-sizing: border-box;
		content: '';
		width: 100%;
		height: 100%;
		border: 1px solid hsl(0, 0%, 20%);
		border-radius: 2px;
		transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
	}
	/* ckeditor5-list/theme/todolist.css */
	.todo-list .todo-list__label > input::after {
		display: block;
		position: absolute;
		box-sizing: content-box;
		pointer-events: none;
		content: '';
		left: calc( 16px / 3 );
		top: calc( 16px / 5.3 );
		width: calc( 16px / 5.3 );
		height: calc( 16px / 2.6 );
		border-style: solid;
		border-color: transparent;
		border-width: 0 calc( 16px / 8 ) calc( 16px / 8 ) 0;
		transform: rotate(45deg);
	}
	/* ckeditor5-list/theme/todolist.css */
	.todo-list .todo-list__label > input[checked]::before {
		background: hsl(126, 64%, 41%);
		border-color: hsl(126, 64%, 41%);
	}
	/* ckeditor5-list/theme/todolist.css */
	.todo-list .todo-list__label > input[checked]::after {
		border-color: hsl(0, 0%, 100%);
	}
	/* ckeditor5-list/theme/todolist.css */
	.todo-list .todo-list__label .todo-list__label__description {
		vertical-align: middle;
	}
	/* ckeditor5-media-embed/theme/mediaembed.css */
	.media {
		clear: both;
		margin: 0.9em 0;
		display: block;
		min-width: 15em;
	}
	/* ckeditor5-page-break/theme/pagebreak.css */
	.page-break {
		position: relative;
		clear: both;
		padding: 5px 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	/* ckeditor5-page-break/theme/pagebreak.css */
	.page-break::after {
		content: '';
		position: absolute;
		border-bottom: 2px dashed hsl(0, 0%, 77%);
		width: 100%;
	}
	/* ckeditor5-page-break/theme/pagebreak.css */
	.page-break__label {
		position: relative;
		z-index: 1;
		padding: .3em .6em;
		display: block;
		text-transform: uppercase;
		border: 1px solid hsl(0, 0%, 77%);
		border-radius: 2px;
		font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
		font-size: 0.75em;
		font-weight: bold;
		color: hsl(0, 0%, 20%);
		background: hsl(0, 0%, 100%);
		box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	/* ckeditor5-table/theme/tablecolumnresize.css */
	.table .ck-table-resized {
		table-layout: fixed;
	}
	/* ckeditor5-table/theme/tablecolumnresize.css */
	.table table {
		overflow: hidden;
	}
	/* ckeditor5-table/theme/tablecolumnresize.css */
	.table td,
	.table th {
		position: relative;
	}
	/* ckeditor5-table/theme/table.css */
	.table {
		margin: 0.9em auto;
		display: table;
	}
	/* ckeditor5-table/theme/table.css */
	.table table {
		border-collapse: collapse;
		border-spacing: 0;
		width: 100%;
		height: 100%;
		border: 1px double hsl(0, 0%, 70%);
	}
	/* ckeditor5-table/theme/table.css */
	.table table td,
	.table table th {
		min-width: 2em;
		padding: .4em;
		border: 1px solid hsl(0, 0%, 75%);
	}
	/* ckeditor5-table/theme/table.css */
	.table table th {
		font-weight: bold;
		background: hsla(0, 0%, 0%, 5%);
	}
	/* ckeditor5-table/theme/table.css */
	.ck-content[dir="rtl"] .table th {
		text-align: right;
	}
	/* ckeditor5-table/theme/table.css */
	.ck-content[dir="ltr"] .table th {
		text-align: left;
	}
	/* ckeditor5-table/theme/tablecaption.css */
	.table > figcaption {
		display: table-caption;
		caption-side: top;
		word-break: break-word;
		text-align: center;
		color: hsl(0, 0%, 20%);
		background-color: hsl(0, 0%, 97%);
		padding: .6em;
		font-size: .75em;
		outline-offset: -1px;
	}
	/* ckeditor5-horizontal-line/theme/horizontalline.css */
	hr {
		margin: 15px 0;
		height: 4px;
		background: hsl(0, 0%, 87%);
		border: 0;
	}
	/* ckeditor5-code-block/theme/codeblock.css */
	pre {
		padding: 1em;
		color: hsl(0, 0%, 20.8%);
		background: hsla(0, 0%, 78%, 0.3);
		border: 1px solid hsl(0, 0%, 77%);
		border-radius: 2px;
		text-align: left;
		direction: ltr;
		tab-size: 4;
		white-space: pre-wrap;
		font-style: normal;
		min-width: 200px;
	}
	/* ckeditor5-code-block/theme/codeblock.css */
	pre code {
		background: unset;
		padding: 0;
		border-radius: 0;
	}
	/* ckeditor5-list/theme/liststyles.css */
	ol {
		list-style-type: decimal;
	}
	/* ckeditor5-list/theme/liststyles.css */
	ol ol {
		list-style-type: lower-latin;
	}
	/* ckeditor5-list/theme/liststyles.css */
	ol ol ol {
		list-style-type: lower-roman;
	}
	/* ckeditor5-list/theme/liststyles.css */
	ol ol ol ol {
		list-style-type: upper-latin;
	}
	/* ckeditor5-list/theme/liststyles.css */
	ol ol ol ol ol {
		list-style-type: upper-roman;
	}
	/* ckeditor5-list/theme/liststyles.css */
	ul {
		list-style-type: circle;
	}
	/* ckeditor5-list/theme/liststyles.css */
	ul ul {
		list-style-type: disc;
	}
	/* ckeditor5-list/theme/liststyles.css */
	ul ul ul {
		list-style-type: square;
	}
	/* ckeditor5-list/theme/liststyles.css */
	ul ul ul ul {
		list-style-type: square;
	}
	/* ckeditor5-mention/theme/mention.css */
	.mention {
		background: hsla(341, 100%, 30%, 0.1);
		color: hsl(341, 100%, 30%);
	}`;
