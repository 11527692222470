import {IPileraCommonModel, PileraCommonModel} from "../../../shared/model/pilera-common.model";
import {isBlank} from "underscore.string";
import {AuthUserRoles} from "../../../shared/services/user.service";
import {isNil} from "../../../shared/components/component-helpers/pilera-component-helpers";
import {pullAll} from "lodash";

declare const URI: any;

/**
 * Created by black7 on 8/24/17.
 */
export class AuthRolesCollection extends PileraCommonModel implements IPileraCommonModel
{

    public authRoles: Array<AuthRole>;

    public scopeType: string = "";

    constructor(

    )
    {
        super("AuthRoleView");

        this.authRoles = new Array();

        console.log("AuthRolesCollection init");

    }


    public getUUId(): string {
        return "";
    }

    public getPath(): string
    {
        return `/auth/roles`;
    }

    public addAuthRole(authRole: AuthRole)
    {
        this.authRoles.push(authRole);
    }

    public getAuthRoles()
    {
        return this.authRoles;
    }

    public getAuthRoleByUUId(roleUUId: string): any
    {
        this.getAuthRoles().map(function(authRole)
        {
            if (authRole.uuid === roleUUId)
            {
                return authRole;
            }
        });

        // TODO: handle when not found

    }

    public getAuthRoleByCode(code: string): any
    {
        let authRoleFinal;

        this.getAuthRoles().map(
            authRole =>
            {
                if (authRole.code === code)
                {
                    authRoleFinal = authRole;
                }
            }
        );

        return authRoleFinal;

    }

    public getAuthRolesByScopeType(scopeType: string): Array<AuthRole>
    {
        return this.getAuthRoles().filter(
            authRole =>
            {
                if (authRole.scopeType === scopeType)
                {
                    return authRole;
                }
            }
        );


    }


}


export class PermissionScopeCollection
{
    private permissionScopes: Array<PermissionScope> = [];

    constructor(
    ) {}

    public addPermissionScope(permissionScope: PermissionScope): void
    {
        this.permissionScopes[permissionScope.scopeType] = permissionScope;
    }

    public getPermissionScopes(): Array<PermissionScope>
    {
        return this.permissionScopes;
    }

	public getPermissionScope(scope: string): PermissionScope
	{
		return this.permissionScopes[scope];
	}

	/**
	 * Returns true if the user has the permission for their current community context.
	 *
	 * @param {string} argPermission
	 * @returns {boolean}
	 */
	public isPermissionPresentForCommunity(argPermission: string): boolean
	{
		return this.isPermissionPresent(argPermission, "Community");
	}

    /**
     * Returns true if permission is in collection, based on passed permission code and scope type
     *
     * @param {string} permission such as "pil.auth.manageRoles"
     * @param {string} scopeType such as "Global", "Client" or "Community" - defaults to nothing to check all allTypeFilters
     * @returns {boolean} true if permission is in collection
     */
    public isPermissionPresent(permission: string, scopeType?: string): boolean {
        let permissionScopes = this.getPermissionScopes();

        if (!isNil(scopeType)) {

            if (isNil(permissionScopes[scopeType])) {
                return false;
            }

            return permissionScopes[scopeType].isPermissionPresent(permission);
        }

        if (permissionScopes['Community']) {
            if (permissionScopes['Community'].isPermissionPresent(permission)) {
                return true;
            }
        }

        if (permissionScopes['Client']) {
            if (permissionScopes['Client'].isPermissionPresent(permission)) {
                return true;
            }
        }

        if (permissionScopes['Global']) {
            if (permissionScopes['Global'].isPermissionPresent(permission)) {
                return true;
            }
        }

        return false;
    }

    public isRoleManagerForAll(): boolean
    {
        return this.isPermissionPresent("pil.auth.manageRoles", "Global");
    }

    public isRoleManagerForCurrentClient(): boolean
    {
        return (this.isRoleManagerForAll() || this.isPermissionPresent("pil.auth.manageRoles", "Client"));
    }

    public isRoleManagerForCurrentCommunity(): boolean
    {
        return (this.isRoleManagerForAll() || this.isRoleManagerForCurrentClient() || this.isPermissionPresent("pil.auth.manageRoles", "Community"));
    }


    /**
     * Returns true if the user is a permission manager
     *
     * @returns {boolean}
     */
    public isConfigurationnManager(): boolean
    {
        return this.isPermissionPresent("pil.auth.manageConfig", "Global");
    }


    public isSystemAdmin(): boolean
    {
        return this.isPermissionPresent("pil.rolePerm.systemAdmin", "Global");
    }

    public isCompanyAdminForCurrentClient(clientUUId: string): boolean
    {
        return this.isPermissionPresent("pil.rolePerm.companyAdmin", "Client");
    }

    public isCommunityManagerForCurrentCommunity(communityUUId: string): boolean
    {
        return this.isPermissionPresent("pil.rolePerm.communityManager", "Community");
    }

	/**
	 * Returns true if the user is a Board Member for the passed community.
	 *
	 * @param {string} communityUUId
	 * @returns {boolean}
	 */
	public isBoardMemberForCommunity(communityUUId: string): boolean
	{
		return this.isPermissionPresent("pil.rolePerm.boardMember", "Community");
	}

	/**
	 * Returns true if the user is a Discussion Board Moderator for the current community.
	 *
	 * @param {string} communityUUId
	 * @returns {boolean}
	 */
	public isDiscussionGroupModeratorForCommunity(communityUUId: string): boolean
	{
		return this.isPermissionPresent("pil.rolePerm.discussionGroupModerator", "Community");
	}

}


export class PermissionScope
{
    private permissions: Array<string> = [];

    constructor(public scopeUUId: string, public scopeType: string) {}

    public addPermission(permission: string): void
    {
        this.permissions.push(permission);
    }

	/**
	 * Removes passed permissions from scope.
	 *
	 * @param {Array<string>} argPermissionsToRemove
	 */
	public removePermissions(argPermissionsToRemove: Array<string>): void
	{
		pullAll(this.permissions, argPermissionsToRemove);
	}

    public getPermissions(): Array<string>
    {
        return this.permissions;
    }

    isPermissionPresent(permission: string): boolean
    {
        return (this.getPermissions().indexOf(permission) > -1);
    }

    // TODO: look into no case sensitivity, constants, etc for these

    public isScopeTypeGlobal(): boolean
    {
        return this.scopeType === "Global";
    }

    public isScopeTypeClient(): boolean
    {
        return this.scopeType === "Client";
    }

    public isScopeTypeCommunity(): boolean
    {
        return this.scopeType === "Community";
    }

}





export class AuthRole
{

    constructor(

        public uuid?: string,
        public scopeType?: string,
        public name?: string,
        public description?: string,
        public code?: string

    ) {}

}



export class AuthUserRolesCollection extends PileraCommonModel implements IPileraCommonModel
{

    // TODO: stop using this- see next property
    public roles: Array<string> = new Array();

    // TODO: when initially built, this class just had a flat array or roles (above)
    // this is not always good- if no scope is specified, the roles can be for any community, as an example
    // this array here attempts to start to fix that
    // TODO: fix any
    public scopedRoles: Array<any> = new Array();



    constructor(
        public clientUUId: string,
        public userUUId: string,
        public scopeType: string,
        public scopes?: Array<string>
    ) {super("AuthUserRoles"); }


    public getUUId(): string {
        return "";
    }

    public getPath(): string
    {
        // NOTE: URI only works in embedded apps right now
        let uri = new URI('/auth/roles/roles/user');

        if (!isBlank(this.clientUUId))
        {
            uri.addSearch("client", this.clientUUId);
        }

        if (!isBlank(this.userUUId))
        {
            uri.addSearch("user", this.userUUId);
        }

        if (!isBlank(this.scopes))
        {
            uri.addSearch("scopes", this.scopes.toString());
        }



        return uri.toString();
    }


    public addRole(roleUUId: string): void
    {
        this.roles.push(roleUUId);
    }


    public addScopedRole(scopedRole: Object): void
    {
        this.scopedRoles.push(scopedRole);
    }


    public getRoles(): Array<string>
    {
        return this.roles;
    }

    public getScopedRoles(): Array<Object>
    {
        return this.scopedRoles;
    }

    /**
     * Returns an array of roles based on passed scope like "Client" or "Community"
     *
     * @param {string} scope
     * @returns {Array<Object>}
     */
    public getScopedRole(scope: string): Array<Object>
    {
        let roles = [];

        this.scopedRoles.forEach((currentScope) =>
        {
            if (currentScope.scope.type === scope)
            {
                roles.push(currentScope);
            }
        })

        return roles;
    }


    // TODO: look into less looping patterns here

    public isUserInRoleForCommunity(roleUUId: string, communityUUId: string): boolean
    {

        let found: boolean = false;

        let roles = this.getScopedRoles();

        let communityRoles;


        for (let scopedRole of roles)
        {
            if (scopedRole['scope'].scope === communityUUId)
            {
                communityRoles = scopedRole;
                break;
            }
        }


        if (communityRoles)
        {
            found = (communityRoles.roles.indexOf(roleUUId) > -1);
        }


        return found;
    }



}












/**
 * A a user auth role that has been selected in the ui
 * Extends the (pcs based) auth role with some helper methods
 * TODO: should these helper methods just go into the super?? maybe
 *
 */
export class SelectedAuthRole extends AuthRole
{
    constructor() {super()}

    /**
     * Returns true if the auth role is set/populated
     *
     * @returns {boolean}
     */
    public isSet(): boolean
    {
        return (!isNil(this.uuid) && this.uuid !== "");
    }

    /**
     * Resets the auth role to unset/not populated
     */
    public reset(): void
    {
        this.uuid = "";
        this.description = "";
        this.scopeType = "";
    }

    /**
     * Populates this object based on passed Auth Role
     *
     * @param authRole
     */
    public populate(authRole: AuthRole): void
    {
        if (isNil(authRole))
        {
            return;
        }

        this.uuid = authRole.uuid;
        this.name = authRole.name;
        this.code = authRole.code;
        this.description = authRole.description;
        this.scopeType = authRole.scopeType;
    }

}
