import {Injectable} from "@angular/core";
import {PileraHttpService} from "./pilera-http.service";
import {AuthUserRolesCollection, PermissionScope, PermissionScopeCollection} from "../../features/auth/auth-model/auth-roles.model";
import {NgRadio} from "ng-radio";
import {DateTime as luxon} from 'luxon';
import {SimpleCommunity} from "../model/community/community.model";
import {ReplaySubject} from "rxjs";
import {isNil} from "../components/component-helpers/pilera-component-helpers";
import {environment} from "../../../environments/environment.local";
import {PortalType} from "../model/enum/portal-type.enum";
import {PileraCommonModel} from "../model/pilera-common.model";


@Injectable()
export class UserService
{

	public user: User;
	public user$: ReplaySubject<User>;

	constructor(
		private pileraHttpService: PileraHttpService,
		private radio: NgRadio
	)
	{

		this.user$ = new ReplaySubject(1);
	}

	/**
	 * getUser requests a user name and uuid
	 * @return {user} Observable<User>
	 */

	// TODO: bad name, should be loadUser, since user is not returned
	public getUser(): Promise<any>
	{
		const promise = new Promise<void>((resolve, reject) =>
		{

			let authUserRolesPromise = this.getAuthUserRoles();

			let userInfoPromise = this.getUserInfo();

			let portalTypePromise = this.getPortalType();


			Promise.all([authUserRolesPromise, userInfoPromise, portalTypePromise]).then(
				(results: any[]) =>
				{

					// TODO: HEYYYY.. i think results[1] has a user object with authUserRoles in it
					// i think the service gave us them.. so no need to call another service maybe
					// TODO: enh.. looks like just permissions. no roles.. what a mess

					this.user = results[1];
					this.user.authUserRoles = results[0];
					this.user.portalType = results[2];

					resolve();
				}

				// TODO: reject!
			);
		});

		return promise;
	}


	private getUserInfo(): Promise<any>
	{
		const promise = new Promise((resolve, reject) =>
		{
			const userUrl = "/pcs/rest/auth/token/context";

			/*

			 .catch(error =>
			 {
			 let redir = `https://${environment.host ? environment.host : "app"}.pilera.com`;

			 // TODO: WHA????? we need to get this out of here
			 document.write(error.userMessage + ` <a class="btn btn-link" target="_blank" href="${redir}">Login</a>`);

			 // TODO: i can never get observables to work like promises, so i had to convert to a promise
			 // pattern for auth z.. i think this is now broken
			 return Observable.throw(error);
			 })

			 */



			this.pileraHttpService.get(userUrl)
			  .subscribe(
				(data) =>
				{
					// TODO: we need a real user class

					let name;
					let uuid;
					let lastLogin;

					let userType;

					let isPretender: boolean = false;

					// if we're in pretender mode, we need to grab the name and uuid from a different property

					if (data["AuthContext"].userReferenceAssumed)
					{
						name = data["AuthContext"].userReferenceAssumed.name;
						uuid = data["AuthContext"].userReferenceAssumed.uuid;
						userType = data["AuthContext"].userType;
						isPretender = true;
					}
					else
					{
						name = data["AuthContext"].userReference.name;
						uuid = data["AuthContext"].userReference.uuid;
						userType = data["AuthContext"].userType;
					}

					lastLogin = luxon.fromISO(data["AuthContext"].sessionDateTime);


					let user = Object.assign({}, {

						name: name,
						uuid: uuid,
						lastLogin: lastLogin,
						userType: userType,

						client: {
							name: data["AuthContext"].clientReference.name,
							uuid: data["AuthContext"].clientReference.uuid
						},

						community: {
							name: data["AuthContext"].communityReference.name,
							uuid: data["AuthContext"].communityReference.uuid
						}


					});


					// TODO: we need a real user class!
					let permissionScopeCollection: PermissionScopeCollection = new PermissionScopeCollection();

					Object.keys(data["AuthContext"].scopePermissions.scopes).forEach(
						(key, index) =>
						{

							let scopeType;

							if (key === user.client.uuid)
							{
								scopeType = "Client";
							}
							else if (key === user.community.uuid)
							{
								scopeType = "Community";
							}
							else if (key === "Global")
							{
								scopeType = "Global";
							}

							if (scopeType)
							{
								let permissionScope = new PermissionScope(key, scopeType);

								data["AuthContext"].scopePermissions.scopes[key].forEach(
									(permission) =>
									{
										permissionScope.addPermission(permission);
									}
								);

								permissionScopeCollection.addPermissionScope(permissionScope);
							}


						});

					this.injectPhpPermissionsIfAvailable(permissionScopeCollection);

					this.removePhpPermissionsIfProvided(permissionScopeCollection);

					user["permissionScopeCollection"] = permissionScopeCollection;
					user["isPretender"] = isPretender;

					resolve(user);

				},
				(error) =>
				{
					alert("(PAA-0501) Sorry, an error occurred. Please try back later.");

					// TODO: think about the error.. 504'sh with no PCS object or not?

					// TODO: cannot do this yet, since we're don't have a UI framework to talk to (this can happen when bootsrapping data)
					// let pileraError = new PileraError();
					// this.radio.cast("pileraapp:error:dialog:modal", pileraError);
				}
			);

			// TODO: reject();

		});

		return promise;

	}

	/**
	 *
	 * Removes permissions that need to be filtered out due to rules like owner created users not having certain perms
	 * that pcs does not know about currently. PVP-7412
	 *
	 * @param {PermissionScopeCollection} permissionScopeCollection
	 * @private
	 */
	private removePhpPermissionsIfProvided(permissionScopeCollection: PermissionScopeCollection): void
	{
		if (!isNil(window["pilera"]) && !isNil(window["pilera"].permissions) && !isNil(window["pilera"].permissions.ignore))
		{
			// php exposed some perms that need to be removed, so we'll pull them out

			let permissionsToRemove = window["pilera"].permissions.ignore;

			permissionScopeCollection.getPermissionScope("Community").removePermissions(permissionsToRemove);
			permissionScopeCollection.getPermissionScope("Client").removePermissions(permissionsToRemove);

		}
	}


	/**
	 * Looks for permissions that PHP may have created and put into the DOM.
	 * This happens for certain perms that PCS cannot currently create.
	 * This method will look for these PHP specific perms and include into the ngx permission scope so it is none the wiser.
	 * Needed for the Resident Dashboard initially.
	 *
	 * @param {PermissionScopeCollection} permissionScopeCollection
	 */
	private injectPhpPermissionsIfAvailable(permissionScopeCollection: PermissionScopeCollection): void
	{
		if (!isNil(window["pilera"]) && !isNil(window["pilera"].permissions))
		{
			let communityPermissions = window["pilera"].permissions.community;

			communityPermissions.forEach((permission) =>
			{
				if (!permissionScopeCollection.isPermissionPresent(permission, "Community"))
				{
					try
					{
						permissionScopeCollection.getPermissionScope("Community").addPermission(permission);
						console.log(`Found community scope permission in php scope that was not in ngx scope, adding: ${permission}`);
					}
					catch (e)
					{
						// as it turns out, it was not simple lazy loading the permission scope collections to ensure we always have one.
						// i doubt this scenario will happen much if at all, since we're calling this method after injecting other non php perms
						console.error(`Failed to add php permission to community scope. This can happen if the client scope is not already initialized.`);
					}
				}
			});

			let clientPermissions = window["pilera"].permissions.client;

			clientPermissions.forEach((permission) =>
			{
				if (!permissionScopeCollection.isPermissionPresent(permission, "Client"))
				{
					try
					{
						communityPermissions.getPermissionScope("Client").addPermission(permission);
						console.log(`Found client scope permission in php scope that was not in ngx scope, adding: ${permission}`);
					}
					catch (e)
					{
						// see comment in community catch, above
						console.error(`Failed to add php permission to client scope. This can happen if the client scope is not already initialized.`);
					}
				}
			});
		}
	}


	// TODO: HEY, this should be promise<AuthUserRoles> .. needs refactoring
	private getAuthUserRoles(): Promise<any>
	{
		const promise = new Promise((resolve, reject) =>
		{
			// TODO: needs ?scope
			const userAuthUrl = "/pcs/rest/auth/roles/roles/user";

			this.pileraHttpService.get(userAuthUrl).subscribe(
				(authUserRolesRaw) =>
				{
					let authUserRoles = new AuthUserRoles();

					authUserRolesRaw["AuthUserRoles"].scopedRoles.forEach(
						scopedRole =>
						{
							authUserRoles.addScopedRole(scopedRole);
						}
					);

					resolve(authUserRoles);
				},
				(error) =>
				{
					alert("(PAA-0500) Sorry, an error occurred. Please try back later.");

					// TODO: think about the error.. 504'sh with no PCS object or not?

					// TODO: cannot do this yet, since we're don't have a UI framework to talk to (this can happen when bootsrapping data)
					// let pileraError = new PileraError();
					// this.radio.cast("pileraapp:error:dialog:modal", pileraError);
				}
			);

			// TODO: reject();

		});

		return promise;
	}

	// TODO: HEY, this should be promise<Number> .. needs refactoring
	// Get's the current portal type from PHP
	private getPortalType(): Promise<any>
	{
		const promise = new Promise((resolve, reject) =>
		{
			let portalType = new PortalTypeModel();
			this.pileraHttpService.get(portalType).subscribe(
				(response) =>
				{
					let portalType = response['portalType'];
					if (portalType === PortalType.RFPONLY) {
						resolve(PortalType.RFPONLY);
					} else  if (portalType === PortalType.COMMHUB) {
						resolve(PortalType.COMMHUB);
					} else {
						resolve(PortalType.PILERA);
					}
				},
				(error) =>
				{
					alert("(PAA-0500) Sorry, an error occurred. Please try back later.");

					// TODO: think about the error.. 504'sh with no PCS object or not?

					// TODO: cannot do this yet, since we're don't have a UI framework to talk to (this can happen when bootsrapping data)
					// let pileraError = new PileraError();
					// this.radio.cast("pileraapp:error:dialog:modal", pileraError);
				}
			);

			// TODO: reject();

		});

		return promise;
	}


	// TODO: HEY, this should be Promise<UserCommunitiesLegacy> needs refactoring
	// TODO: DEPRECATED, use pileraUserCommunityService.getUserCommunitiesForClientCollection()
	/**
	 *
	 * @deprecated
	 * @returns {Promise<any>}
	 */
	public getUserCommunitiesLegacy(): Promise<any>
	{
		const promise = new Promise((resolve, reject) =>
		{
			const url = "/pcs/rest/user/manager/communities";

			this.pileraHttpService.get(url).subscribe(
				communitiesRaw =>
				{
					let userCommunities = new UserCommunitiesLegacy();

					communitiesRaw["CommunityView"].communities.map(
						community =>
						{
							let simpleCommunity = Object.assign(new SimpleCommunity(community.uuid, community.name), community);
							userCommunities.addCommunity(simpleCommunity);
						}
					);

					resolve(userCommunities);
				}
			);

			// TODO: reject();

		});

		return promise;
	}


}


// TODO: DEPRECATED i think, check out AuthUserRolesCollection
export class AuthUserRoles
{
	private scopedRoles: Array<Object> = [];

	// lazy load pattern used, do NOT init
	private systemAdmin: boolean;

	constructor()
	{
	}

	public getScopedRoles(): Array<Object>
	{
		return this.scopedRoles;
	}

	// TODO: fix any
	public addScopedRole(scopedRole: any): void
	{
		this.scopedRoles.push(scopedRole);
	}


	/**
	 * Returns true id the user is a system admin
	 * NOTE: in theory, we should not be operating on a user roles in the app.. use this sparingly
	 * Over time, we'll be able to use permissions for most stuf
	 *
	 * @returns {boolean}
	 */
	// TODO: this may not be the best way to tell if sys admin, talk to ron

	// TODO: use permissions, pil.admin.superManager - php already doing this

	public isSystemAdmin(): boolean
	{
		// lazy load pattern here
		if (this.systemAdmin)
		{
			return this.systemAdmin;
		}

		let isSupported = false;

		this.getScopedRoles().forEach(
			(scopedRole) =>
			{
				// TODO: YUK.. Global
				if (scopedRole["scope"].type === "Global")
				{
					isSupported = true;
					return;
				}

				// console.log("SCOPED ROLE");
			});

		// lazy load pattern here
		this.systemAdmin = isSupported;

		return isSupported;
	}


	public isClientRolesManager(): boolean
	{
		// TODO: implement lazy load.. maybe start to combine with above method

		let isSupported = false;

		this.getScopedRoles().forEach(
			(scopedRole) =>
			{
				// TODO: YUK.. Global
				if (scopedRole["scope"].type === "Global")
				{
					isSupported = true;
					return;
				}

				// console.log("SCOPED ROLE");
			});

		// lazy load pattern here
		this.systemAdmin = isSupported;

		return isSupported;


	}

}


export class UserCommunitiesLegacy
{
	private communities: Array<SimpleCommunity> = new Array();

	private communityUUids: Array<string> = new Array();

	constructor()
	{
	}

	public addCommunity(simpleCommunity: SimpleCommunity)
	{
		this.communities.push(simpleCommunity);
		this.communityUUids.push(simpleCommunity.uuid);
	}

	public getCommunities(): Array<SimpleCommunity>
	{
		return this.communities;
	}

	public getCommunityUUIds(): Array<string>
	{
		return this.communityUUids;
	}

}





// TODO: this needs to be a class, so we can put helper methods on it, etc
export interface User
{
	name: string,
	uuid: string,
	lastLogin: luxon,
	client: {
		name: string,
		uuid: string
	},
	community: {
		name: string,
		uuid: string
	},
	// @deprecated, use authUserRolesCollection
	authUserRoles: AuthUserRoles,

	authUserRolesCollection: AuthUserRolesCollection,
	permissionScopeCollection: PermissionScopeCollection,

	isPretender: boolean,

	portalType: number

}

class PortalTypeModel extends PileraCommonModel
{
	constructor() {
		super("PortalType", "php");
	}

	public getUUId(): any
	{
		return null;
	}

	public getPath(): string
	{
		return `/portaltype`;
	}
}
