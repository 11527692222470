// This file includes polyfills needed by Angular 2 and is loaded before
// the app. You can add your own extra polyfills to this file.
/*
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/reflect';

import 'core-js/es/reflect';
*/
import 'zone.js';

// https://stackoverflow.com/questions/67627208/angular-12-aws-sdk-2-910-0-error-module-not-found-error-cant-resolve-util
// (window as any).global.util = (window as any).global.util || require("util").util;

// https://github.com/twilio/twilio-client.js/issues/284
(window as any).process = { env: { DEBUG: undefined }, };
