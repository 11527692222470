import {APP_BASE_HREF, CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";

import {RouterModule, Routes} from "@angular/router";
import {ValidatorsModule} from "ngx-validators";
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from "ngx-mask";
import {GrowlModule, GrowlService} from "./shared/growl";
import {AppComponent} from "./app.component";
import {UserService} from "./shared/services/user.service";
import {NgRadio} from "ng-radio";
import {NguiAutoCompleteComponent, NguiAutoCompleteModule} from "@ngui/auto-complete";
import {PileraHttpService} from "./shared/services/pilera-http.service";
import {UserResolver} from "./user-resolve.guard";
import {PileraPortalBridgeService} from "./shared/services/pilera-portal-bridge.service";
import {MatTabsModule} from "@angular/material/tabs";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {FullCalendarModule} from "@fullcalendar/angular";
import {PileraModSecInterceptor} from "./shared/interceptors/pilera-mod-sec-interceptor.service";
import {FaIconLibrary} from "@fortawesome/angular-fontawesome";
import {ScrollToModule} from "@nicky-lenaers/ngx-scroll-to";
import {PileraFileHelpers} from "./shared/components/helpers/pilera-file.helpers";
import {ToastrModule} from "ngx-toastr";
import {ConfirmationService} from "primeng/api";
import {ModalModule} from "ngx-bootstrap/modal";
import {NgSelectModule} from "@ng-select/ng-select";

const appRoutes: Routes = [

  {path: 'filelibrary/:clientuuid', loadChildren: () => import('./features/file-library/file-library-admin.module').then(m => m.FileLibraryAdminModule), resolve: {user: UserResolver}},

  {path: 'templates/:clientuuid', loadChildren: () => import('./features/templates/templates-admin.module').then(m => m.TemplatesAdminModule), resolve: {user: UserResolver}},

  {path: 'calendar/:clientuuid/:communityuuid', loadChildren: () => import('./features/calendar/calendar-admin/calendar-admin.module').then(m => m.CalendarAdminModule), resolve: {user: UserResolver}},
  
	{path: 'import/:clientuuid/:communityuuid', loadChildren: () => import('./features/community/import/excel/validation/excel-import-validation.module').then(m => m.ExcelImportValidationModule), resolve: {user: UserResolver}},

	{path: 'vendormanagement', loadChildren: () => import('./features/vendor/vendor.module').then(m => m.VendorModule), resolve: {user: UserResolver}},

  {path: 'configuration/:clientuuid', loadChildren: () => import('./features/config/config.module').then(m => m.ConfigModule), resolve: {user: UserResolver}},

	{path: 'distributiongroups/:clientuuid/:communityuuid', loadChildren: () => import('./features/distribution-group/distribution-group-admin.module').then(m => m.DistributionGroupAdminModule),
		resolve: {user: UserResolver}},


	// TODO: when uncommented, it breaks.
  // {path: 'index.php/manager/occupants/occupantdetail/:mid', loadChildren: './features/tops/tops-pass-thru-embed/tops-pass-thru-embed.module#TopsPassThruEmbedModule', resolve: {user: UserResolver}},

  {path: 'index.php/manager/pets',
    loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}},

  {path: 'index.php/manager/contacts',
	loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}},


	{path: 'index.php/manager/occupants/occupantdetail/:mid',
    loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}},

  {path: 'index.php/manager/occupants/occupantdetail/:mid/:tabIndex',
    loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}},

  {path: 'index.php/resident/occupants/pets', loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}},


  {path: 'index.php/resident/tickets/tops/:function',
    loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}},

  {path: 'index.php/manager/authorization/:function/:clientuuid',
    loadChildren: () => import('./features/auth/auth-embed/auth-embed.module').then(m => m.AuthEmbedModule), resolve: {user: UserResolver}},

    // TODO: not sure if this is used.. may not be valid without a client uuid context now
  {path: 'index.php/manager/authorization/:function',
    loadChildren: () => import('./features/auth/auth-embed/auth-embed.module').then(m => m.AuthEmbedModule), resolve: {user: UserResolver}},


  // these entries support the superrouter module - where we don't use path mapping, but instead the pattern like:
  // <app-root embeddedapp="true" showApp="fileLibraryEmbed"></app-root> where the showApp is used to determine the app to load
  // this is only used for embedded (in php and ez) apps
  // TODO: figure out how to just to /* instead of this ignore stuf
  { path: "index.php/:area", loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}},
  { path: "index.php/:area/:ignored1", loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}},
  { path: "index.php/:area/:ignored1/:ignored2", loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}},
  { path: "index.php/:area/:ignored1/:ignored2/:ignored3", loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}},



  // NOTE: do not do "ignored1:/etc" - have a defined root, or things don't work
  { path: "index.cfm/:ignored1", loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}},
  { path: "page/:ignored1", loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}},
  { path: "index.cfm", loadChildren: () => import('./features/super-router/super-router.module').then(m => m.SuperRouterModule), resolve: {user: UserResolver}}



];





@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ValidatorsModule,
		NgxMaskDirective,
		NgxMaskPipe,
        HttpClientModule,
        GrowlModule,
        NguiAutoCompleteModule,
        MatTabsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-right'
        }),
        // TODO: unless we expect to use full calendar all over, we should not have this here in this global module
        FullCalendarModule,
        RouterModule.forRoot(appRoutes, {}),
        ScrollToModule.forRoot(),
	    ModalModule.forRoot(),
	    NgSelectModule
    ],
    exports: [
        RouterModule,
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: "" },
        { provide: HTTP_INTERCEPTORS, useClass: PileraModSecInterceptor, multi: true },
        //{provide: MATERIAL_COMPATIBILITY_MODE, useValue: true},
        PileraPortalBridgeService,
        UserResolver,
        UserService,
        PileraHttpService,
        GrowlService,
        NgRadio,
        PileraFileHelpers,
		provideNgxMask(),
		ConfirmationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

	constructor(library: FaIconLibrary)
	{
		// TODO: fas and faCoffee stopped working here Apr 2022, so disabling all of this. rumor has it we don't need it
		//library.addIconPacks(fas)
		//library.addIcons(faCoffee, faBug, faInfoCircle, faWrench, faTrash, faExclamationTriangle, faQuestionCircle);
	}


}
