import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GrowlComponent } from './growl.component';
import { GrowlService } from './growl.service';

@NgModule({
    imports: [CommonModule],
    exports: [GrowlComponent],
    declarations: [GrowlComponent],
    providers: [GrowlService],
})
export class GrowlModule { }
