import {Injectable} from "@angular/core";
import {PileraCommonErrorDialogComponent} from "./pilera-common-error-dialog.component";
import {BsModalService} from "ngx-bootstrap/modal";

@Injectable({
	providedIn: 'root'
})
export class PileraCommonErrorDialogService {
	constructor(private modalService: BsModalService) {
	}
	show(title: string = "(PNA-0050) Sorry, an error occurred.", message: string = "Please try back later.") {

		const initialState = { message, title };

		let bsModalRef = this.modalService.show(PileraCommonErrorDialogComponent, { initialState: initialState, animated: true, keyboard: true, backdrop: true, ignoreBackdropClick: false });

	}
}
