import {Injectable} from "@angular/core";
import {EXENTIONS_IMAGE_BROWSER_SUPPORTED} from "../../../globals";
import {isNilOrBlank} from "../component-helpers/pilera-component-helpers";

/**
 * A helper class that contains common file type functions.
 */
@Injectable()
export class PileraFileHelpers
{
	/**
	 * Constructor.
	 */
	constructor()
	{
	}

	/**
	 * Returns the extension of passed file name or empty string if none found.
	 *
	 * @param {string} argFileName
	 * @returns {string}
	 */
	public getFileExtension(argFileName: string): string
	{
		let extension = argFileName.substring(argFileName.lastIndexOf(".") + 1);

		if (isNilOrBlank(extension)) return "";

		return extension.toLowerCase();
	}

	/**
	 * Returns true if the passed file appears to be of an image type that a browser and html would use.
	 *
	 * @param {string} argFileName
	 * @returns {boolean}
	 */
	public isFileAnImageThatABrowserCanDisplay(argFileName: string): boolean
	{
		let extension = this.getFileExtension(argFileName);

		if (isNilOrBlank(extension)) return false;

		return (EXENTIONS_IMAGE_BROWSER_SUPPORTED.indexOf(this.getFileExtension(extension)) > -1);
	}
}
